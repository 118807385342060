import { GoogleDate } from "../../../../models/GoogleDate";

type MediaFormat = "PHOTO" | "VIDEO";

export interface MonthlyMetric {
  key: MetricKey | string;
  data: {
    start: string;
    value: number;
  }[];
  total: number;
}

export interface MonthlyMetricEntry {
  start: string;
  value: number;
}

export interface YoyValue {
  lastYearData: number;
  thisYearData: number;
}

export interface CombinedMonthlyMetric {
  key: string | MetricKey;
  dataSets: MonthlyMetric[];
  yoy?: YoyValue;
}

export type PostType = "STANDARD" | "EVENT" | "OFFER";

export interface LocalPost {
  callToAction?: {
    actionType: string;
    url: string;
  };
  createTime: string;
  languageCode: string;
  media?: {
    googleUrl: string;
    mediaFormat: MediaFormat;
    name: string;
  }[];
  name: string;
  searchUrl: string;
  state: string;
  summary: string;
  topicType: PostType;
  updateTime: string;
  event?: {
    schedule: {
      startDate: GoogleDate;
      endDate: GoogleDate;
    };
    title: string;
  };
}

export type MediaType =
  | "COVER"
  | "PROFILE"
  | "LOGO"
  | "EXTERIOR"
  | "INTERIOR"
  | "PRODUCT"
  | "AT_WORK"
  | "FOOD_AND_DRINK"
  | "MENU"
  | "COMMON_AREA"
  | "ROOMS"
  | "TEAMS"
  | "ADDITIONAL";

interface Media {
  createTime: string;
  dimensions: {
    widthPixels: number;
    heightPixels: number;
  };
  googleUrl: string;
  mediaFormat: MediaFormat;
  name: string;
  thumbnailUrl: string;
  locationAssociation?: {
    category: MediaType;
  };
  insights: {
    viewCount: string;
  };
}

export interface ComparisonValue {
  metric: string;
  totalValue: {
    metricOption: string;
    timeDimension: {
      startTime: string;
      endTime: string;
    };
    value: number;
  };
}

export interface ComparisonData {
  thisYearData: ComparisonValue[];
  lastYearData: ComparisonValue[];
}

export interface ToolsMetrics {
  actionMetrics: {
    metric: MetricKey;
    totalValue: number;
  }[];
  title: string;
  address: string;
  checklist: Record<string, boolean>;
  localPosts: LocalPost[];
  customerMedia: {
    mediaItems: Media[];
    totalMediaItemCount: number;
  };
  mediaData: {
    mediaItems: Media[];
    totalMediaItemCount: number;
  };
  monthlyMetrics: MonthlyMetric[];
  searchKeywords: {
    year: number;
    month: number;
    keywords: {
      searchKeyword: string;
      insightsValue: {
        value: string;
      };
    }[];
  }[];
  skipNum: number;
  yoyComparison?: ComparisonData;
}

export enum MetricKey {
  TOTAL_BUSINESS_IMPRESSIONS = "TOTAL_BUSINESS_IMPRESSIONS",
  CALL_CLICKS = "CALL_CLICKS",
  BUSINESS_IMPRESSIONS_DESKTOP_SEARCH = "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH",
  BUSINESS_IMPRESSIONS_MOBILE_SEARCH = "BUSINESS_IMPRESSIONS_MOBILE_SEARCH",
  BUSINESS_FOOD_ORDERS = "BUSINESS_FOOD_ORDERS",
  BUSINESS_IMPRESSIONS_MOBILE_MAPS = "BUSINESS_IMPRESSIONS_MOBILE_MAPS",
  BUSINESS_IMPRESSIONS_DESKTOP_MAPS = "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
  BUSINESS_DIRECTION_REQUESTS = "BUSINESS_DIRECTION_REQUESTS",
  BUSINESS_BOOKINGS = "BUSINESS_BOOKINGS",
  WEBSITE_CLICKS = "WEBSITE_CLICKS"
}
