import { useQuery } from "@tanstack/react-query";
import nprogress from "accessible-nprogress";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { LocationData } from "../../../models/LocationData";
import DataSvc from "../../../services/dataSvc";
import { Checkbox } from "../../FormElement/Checkbox";
import InputBox from "../../FormElement/InputBox";

import "./styles.scss";

export interface IModalNewAnalysisProps {
  onClose: () => void;
  onSave: (formData: any) => void;
}

export interface IAnalyseAddFormData {
  locations: LocationData[] | null;
  languages: ILanguage[] | null;
  start_date: string | null;
  end_date: string | null;
}

interface ILanguage {
  languageCode: string;
  title: string;
}

export const ModalNewReviewAnalysis: React.FunctionComponent<
  IModalNewAnalysisProps
> = (props) => {
  const { onClose, onSave } = props;
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`customizedReviewsAnalysisPage.modalNewReviewAnalysis.${key}`);

  const languages = [
    { languageCode: "ja", title: t("language.ja") },
    { languageCode: "en", title: t("language.en") },
    { languageCode: "zh", title: t("language.zh") },
    { languageCode: "ko", title: t("language.ko") },
    { languageCode: "es", title: t("language.es") },
    { languageCode: "fr", title: t("language.fr") },
    { languageCode: "de", title: t("language.de") },
    { languageCode: "it", title: t("language.it") },
    { languageCode: "th", title: t("language.th") },
    { languageCode: "pt", title: t("language.pt") },
    { languageCode: "vi", title: t("language.vi") },
    { languageCode: "nl", title: t("language.nl") },
    { languageCode: "ru", title: t("language.ru") },
    { languageCode: "pl", title: t("language.pl") },
    { languageCode: "id", title: t("language.id") },
    { languageCode: "he", title: t("language.he") },
    { languageCode: "la", title: t("language.la") },
    { languageCode: "da", title: t("language.da") },
    { languageCode: "tr", title: t("language.tr") },
    { languageCode: "ca", title: t("language.ca") },
  ];

  const [selectedLocations, setSelectedLocations] = useState<LocationData[]>(
    []
  );
  const [selectedLanguages, setSelectedLanguages] = useState<ILanguage[]>([]);
  const [locationData, setLocationsData] = useState<LocationData[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [searchName, setSearchName] = useState<string>("");
  const [formData, setFormData] = useState<IAnalyseAddFormData>({
    locations: null,
    languages: null,
    start_date: null,
    end_date: null,
  });

  useEffect(() => {
    nprogress.configure({ parent: ".modal-mains" });
  }, []);

  const { data: locations, refetch: refetchLocations } = useQuery(
    [`locations`],
    async () => {
      try {
        const res = await DataSvc.getLocations({
          query: searchName.trim(),
          limit: 1000,
        });
        return res;
      } catch (e) {
        toast.error(`${_t("error.fetching_locations")}: \n${e?.toString()}`, {
          autoClose: 5000,
        });
      }
    }
  );

  useEffect(() => {
    if (locations) {
      setLocationsData(locations.list);
    }
  }, [locations]);

  const renderLocationList = () => {
    return (
      <div className="location-list">
        {locationData.map((location: any) => (
          <div key={location.id}>
            <Checkbox
              id={location.id}
              label={location.name}
              checked={selectedLocations.some((l) => l.id === location.id)}
              onChange={(checked: boolean) => {
                const updatedLocations = checked ? [location] : [];
                setSelectedLocations(updatedLocations);
                setFormData({
                  ...formData,
                  locations: updatedLocations,
                });
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderLanguageList = () => {
    return (
      <>
        <div>
          <Checkbox
            id="select-all-languages"
            label={t("select_all_language")}
            checked={selectedLanguages.length === languages.length}
            onChange={(checked: boolean) => {
              const updatedLanguages = checked ? languages : [];
              setSelectedLanguages(updatedLanguages);
              setFormData({
                ...formData,
                languages: updatedLanguages,
              });
            }}
          />
        </div>
        <div className="language-list">
          {languages.map((language) => (
            <div key={language.languageCode}>
              <Checkbox
                id={language.languageCode}
                label={language.title}
                checked={selectedLanguages.some(
                  (l) => l.languageCode === language.languageCode
                )}
                onChange={(checked: boolean) => {
                  const updatedLanguages = checked
                    ? [...selectedLanguages, language]
                    : selectedLanguages.filter(
                        (l) => l.languageCode !== language.languageCode
                      );
                  setSelectedLanguages(updatedLanguages);
                  setFormData({
                    ...formData,
                    languages: updatedLanguages,
                  });
                }}
              />
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="modal modal-default modal-new-analysis">
      <div className="modal-mains">
        <button
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        />

        <div className="modal-mains__main">
          <div className="top-title flex-grid">{t("new_analysis")}</div>
          <hr />
          <div className="search-module">
            <div className="search-container">
              <InputBox
                value={searchName}
                placeholder={t("search_by_name")}
                isSearch={true}
                classNameContainer={"search-input"}
                onChange={(value: string) => {
                  setSearchName(value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    refetchLocations();
                  }
                }}
              />
              <button
                className={`btn btn-blue search-btn`}
                onClick={() => {
                  refetchLocations();
                }}
              >
                {t("search")}
              </button>
            </div>
          </div>
          <div className="title-bar row-form mb60">
            <div className="top-title flex-grid">{t("location")}</div>
            {renderLocationList()}
          </div>

          <div className="language-list-container">
            <div className="top-title">{t("language_list_title")}</div>
            <div className="top-title">{t("language_setting")}</div>
            {renderLanguageList()}
            <div className="top-title">{t("target_period")}</div>

            <div className="select-date-picker-container">
              <DatePicker
                className="input-date-picker"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date as Date);
                  setFormData({
                    ...formData,
                    start_date: date ? dayjs(date).format("YYYY-MM-DD") : null,
                  });
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                minDate={new Date("2011-01-01")}
                maxDate={new Date()}
                isClearable={true}
              />
              ~
              <DatePicker
                className="input-date-picker"
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date as Date);
                  setFormData({
                    ...formData,
                    end_date: date ? dayjs(date).format("YYYY-MM-DD") : null,
                  });
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate || new Date("2011-01-01")}
                maxDate={new Date()}
                isClearable={true}
              />
            </div>
          </div>

          <div className="bottom-btns">
            <button
              className="btn btn-border"
              onClick={() => {
                onClose();
              }}
            >
              {t("cancel")}
            </button>
            <button
              className={`btn btn-blue ${
                !formData.locations ||
                !formData.languages ||
                formData.languages?.length === 0 ||
                !formData.start_date ||
                !formData.end_date
                  ? "disabled"
                  : ""
              }`}
              onClick={() => {
                if (
                  formData.locations &&
                  formData.languages !== null &&
                  formData.languages.length > 0 &&
                  formData.start_date &&
                  formData.end_date
                ) {
                  onSave(formData);
                }
              }}
            >
              {t("start")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalNewReviewAnalysis;
