import { useMutation, useQuery } from "@tanstack/react-query";
import nprogress from "accessible-nprogress";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import ModalNewReviewAnalysis, {
  IAnalyseAddFormData,
} from "../../components/CustomizedReviewAnalysisComponents/CreateNewAnalysisModal";
import ModalNewReviewAnalysisConfirm from "../../components/CustomizedReviewAnalysisComponents/CreateNewAnalysisModalConfirmModal";
import CustomizedReviewsList from "../../components/CustomizedReviewAnalysisComponents/CustomizedReviewList";
import ModalConfirm from "../../components/ModalConfirm";
import { ORDER } from "../../config";
import { useMe } from "../../hooks/queries";
import {
  LocationReviewSummary,
  LocationReviewSummaryStatus,
} from "../../models/LocationReviewSummary";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

interface IFilterFormData {
  status: LocationReviewSummaryStatus | null;
  publicly_visible: boolean | null;
  sort: string;
  order: string;
  numberPerPage: number;
  pageIndex: number;
}

const defaultFilterFormData = {
  status: null,
  order: ORDER.DESC,
  sort: "id",
  numberPerPage: 20,
  pageIndex: 1,
  publicly_visible: false,
};

const CustomizedReviewsAnalysisPage: React.FC = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`customizedReviewsAnalysisPage.${key}`);

  // eslint-disable-next-line
  const { data: me } = useMe();

  const [filterFormData, setFilterFormData] = useState<IFilterFormData>(
    defaultFilterFormData
  );

  const [deleteCustomReviewSummaryId, setDeleteCustomReviewSummaryId] =
    useState<number | null>(null);

  const [newAnalysisFormData, setNewAnalysisFormData] =
    useState<IAnalyseAddFormData>({
      locations: null,
      languages: [],
      start_date: null,
      end_date: null,
    });

  const [showModalNewAnalysis, setShowModalNewAnalysis] = useState(false);
  const [showModalDeleteReviewSummary, setShowModalDeleteReviewSummary] =
    useState(false);
  const [showModalNewAnalysisConfirm, setShowModalNewAnalysisConfirm] =
    useState(false);

  const { data, refetch: refetchReviewSummaries } = useQuery<{
    list: LocationReviewSummary[];
    totalCount: number;
  }>(["review-summaries", filterFormData], () =>
    DataSvc.getReviewSummaries({
      sort: filterFormData.sort,
      order: filterFormData.order,
      limit: filterFormData.numberPerPage,
      offset: (filterFormData.pageIndex - 1) * filterFormData.numberPerPage,
      ...(filterFormData.status && { status: filterFormData.status }),
      ...(filterFormData.publicly_visible !== null && {
        publicly_visible: filterFormData.publicly_visible,
      }),
    })
  );

  const generateCustomReviewSummariesMutation = useMutation(
    ({
      locationId,
      startDate,
      endDate,
      languages,
    }: {
      locationId: string;
      startDate: string;
      endDate: string;
      languages: string[];
    }) => {
      return DataSvc.generateCustomReviewSummaries(
        _.pickBy({ locationId, startDate, endDate, languages })
      );
    }
  );

  const deleteReviewSummaryMutation = useMutation(
    () => {
      if (deleteCustomReviewSummaryId !== null) {
        return DataSvc.deleteCustomReviewSummary(deleteCustomReviewSummaryId);
      }
      return Promise.reject(new Error("Invalid review summary ID"));
    },
    {
      onSuccess: () => {
        setDeleteCustomReviewSummaryId(null);
        refetchReviewSummaries();
        toast.success(t("analysis_deleted"));
      },
    }
  );

  return (
    <>
      <div className="right-content customized-reviews-analysis">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("customized_reviews_analysis")}</div>
          <div className="line"></div>

          <div className="rights">
            <div className="btn">
              <div
                className="btn btn-border"
                onClick={() => {
                  setShowModalNewAnalysis(true);
                }}
              >
                <i className="icons icon-add"></i>
                {t("new_analysis")}
              </div>
            </div>
          </div>
        </div>

        <CustomizedReviewsList
          reviewSummariesData={data?.list ?? []}
          totalCount={data?.totalCount ?? 0}
          filterFormData={filterFormData}
          onChangeFilterFormData={setFilterFormData}
          onDeleteReviewSummary={(id) => {
            setDeleteCustomReviewSummaryId(id);
            setShowModalDeleteReviewSummary(true);
          }}
        />
      </div>

      {showModalNewAnalysis && (
        <ModalNewReviewAnalysis
          onClose={() => setShowModalNewAnalysis(false)}
          onSave={(formData) => {
            setShowModalNewAnalysis(false);
            setNewAnalysisFormData(formData);

            setShowModalNewAnalysisConfirm(true);
          }}
        />
      )}

      {showModalDeleteReviewSummary && (
        <ModalConfirm
          title={"delete_analysis"}
          onClose={() => setShowModalDeleteReviewSummary(false)}
          onConfirm={() => {
            deleteReviewSummaryMutation.mutate();
            setShowModalDeleteReviewSummary(false);
          }}
          cancelLabel={"cancel"}
          confirmLabel={"delete_confirm"}
        />
      )}

      {showModalNewAnalysisConfirm && (
        <ModalNewReviewAnalysisConfirm
          formData={newAnalysisFormData}
          onClose={() => setShowModalNewAnalysisConfirm(false)}
          onSave={() => {
            generateCustomReviewSummariesMutation
              .mutateAsync({
                startDate: newAnalysisFormData.start_date || "",
                endDate: newAnalysisFormData.end_date || "",
                locationId: newAnalysisFormData.locations
                  ? String(newAnalysisFormData.locations[0].id)
                  : "",
                languages: newAnalysisFormData.languages
                  ? newAnalysisFormData.languages.map(
                      (language) => language.languageCode
                    )
                  : [],
              })
              .then(() => {
                setShowModalNewAnalysisConfirm(false);
                toast.success(t("analysis_created"));
                nprogress.start();
                setTimeout(() => {
                  refetchReviewSummaries();
                  nprogress.done();
                }, 5000);
              });
          }}
        />
      )}
    </>
  );
};

export default CustomizedReviewsAnalysisPage;
