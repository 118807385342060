import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

interface IHookFormErrorProps {
  error?: string | undefined;
}

export const HookFormError = ({ error }: IHookFormErrorProps) => {
  const { t } = useTranslation();
  return (
    <div className="hook-form-error">
      {error && (
        <>
          <div className="error-icon"></div>
          <span className="error-message">{t(error)}</span>
        </>
      )}
    </div>
  );
};
