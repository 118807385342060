import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmCloseContext } from "../../../contexts/confirmClose";
import { LocationData } from "../../../models/LocationData";
import ModalConfirm from "../../ModalConfirm";
import { EditProfileForm } from "../EditProfileForm";

import "./styles.scss";

interface IModalEditProfileProps {
  locationData: LocationData;
  onClose: () => void;
}

export const ModalEditProfile: React.FunctionComponent<
  IModalEditProfileProps
> = (props) => {
  const { locationData, onClose } = props;

  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityDetailsPage.modalEditProfile.${key}`);

  const [isConfirmClose, setIsConfirmClose] = useState<string | undefined>(
    undefined
  );

  const { getRegistrations } = useContext(ConfirmCloseContext);

  const handleClose = () => {
    const closeRegistrations = getRegistrations();
    if (closeRegistrations.length === 0) {
      onClose();
    } else {
      let message = closeRegistrations[0].message ?? t("default_message");
      if (closeRegistrations.length > 1) {
        message = t("multiple_changes");
      }
      setIsConfirmClose(message);
    }
  };

  return (
    <>
      <div className="modal modal-default modal-edit-profile">
        <div className="modal-mains">
          <button className="btn-close" onClick={handleClose}></button>
          <div className="modal-mains__body">
            <div className="top-title">
              <h2>{t("edit_profile")}</h2>
            </div>
            <EditProfileForm locationData={locationData} />
          </div>
        </div>
      </div>
      {isConfirmClose && (
        <ModalConfirm
          title="close_dirty"
          cancelLabel="cancel"
          confirmLabel="confirm"
          onClose={() => {
            setIsConfirmClose(undefined);
          }}
          onConfirm={() => {
            setIsConfirmClose(undefined);
            onClose();
          }}
        />
      )}
    </>
  );
};
