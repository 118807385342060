import React from "react";

import { ImpersonateStatus, useImpersonate } from "../../../hooks/impersonate";

interface IImpersonateOnlyProps {
  children?: React.ReactNode;
  /**
   * @description True by default. If set to false, the component will render the children regardless of the impersonate status.
   * @example
   * // Instead of :
   * { adminOnly
   *  ? <ImpersonateOnly><Content /></ImpersonateOnly>
   *  : <Content />
   * }
   *
   * // You can simplify to:
   * <ImpersonateOnly enabled={adminOnly}><Content /></ImpersonateOnly>
   */
  enabled?: boolean;
}

export const ImpersonateOnly: React.FunctionComponent<
  IImpersonateOnlyProps
> = ({ children, enabled = true }) => {
  const { impersonateStatus } = useImpersonate();
  if (!enabled) {
    return <>{children}</>;
  }
  return <>{impersonateStatus === ImpersonateStatus.IMPERSONATE && children}</>;
};
