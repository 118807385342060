import React from "react";
import { useTranslation } from "react-i18next";

import { useMe } from "../../../hooks/queries";
import { AccountType } from "../../../models/AccountData";
import { LabelData } from "../../../models/LabelData";
import { LocationData } from "../../../models/LocationData";
import { OrganizationData } from "../../../models/OrganizationData";
import LabelsList from "../../FacilityListComponents/LabelsList";
import { CategoriesDisplay } from "../CategoriesDisplay";
import { QRCodeDisplay } from "../QRCodeDisplay";

import "./styles.scss";

export interface IBasicProfileProps {
  organizationId: string;
  locationData: LocationData;
  onDeleteLabel: (indexLabel: number, hasConfirm: boolean) => void;
  onAddLabel: (label: LabelData) => void;
  onClickReviewCounts?: () => void;
}

export const BasicProfile: React.FunctionComponent<IBasicProfileProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityDetailsPage.basicProfile.${key}`);

  const {
    organizationId,
    locationData,
    onDeleteLabel,
    onAddLabel,
    onClickReviewCounts,
  } = props;

  // TODO: Fix me
  const { data: me } = useMe();

  const isOrgUser = () => {
    return me?.type !== AccountType.LOC_ADMIN;
  };

  return (
    <div className="white-border-panel basic-profile-module padding32">
      <div className="row">
        <div className="col">
          <div className="data-group">
            <div className="data-title">{t("municipality")}</div>
            <div className="data-row">
              <div className="values">{locationData.prefecture}</div>
              <div className="values">{locationData.city}</div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="data-group">
            <div className="data-title">{t("category")}</div>
            <div className="data-row">
              <div className="values">
                <CategoriesDisplay locationData={locationData} />
              </div>
            </div>
          </div>
        </div>
        <div className="col sp-row">
          <div className="data-group">
            <div className="data-title">{t("organizations")}</div>
            <div className="data-row">
              {locationData.organizations?.map(
                (
                  itemOrganization: OrganizationData,
                  indexOrganization: number
                ) => (
                  <div className="values" key={indexOrganization}>
                    {itemOrganization.name}
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        {isOrgUser() && (
          <div className="col sp-row">
            <div className="data-group">
              <div className="data-title">{t("labels")}</div>
              <div className="data-row">
                <LabelsList
                  organizationId={organizationId}
                  dataList={locationData.labels}
                  onDeleteLabel={(indexLabel: number, hasConfirm: boolean) => {
                    onDeleteLabel(indexLabel, hasConfirm);
                  }}
                  onAddLabel={(label: LabelData) => {
                    onAddLabel(label);
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <div className="col sp-row">
          <div className="data-group">
            <div className="data-title">{t("rating")}</div>
            <div className="data-row">
              {locationData?.total_review_count ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                  }}
                >
                  <div className="values">
                    {locationData?.average_rating || "-"}
                  </div>
                  ⭐
                  <div className="sub-values" onClick={onClickReviewCounts}>
                    {`(${locationData?.total_review_count || "0"})`}
                  </div>
                </div>
              ) : (
                <div className="values">{t("no_reviews")}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <QRCodeDisplay
        content={`https://search.google.com/local/writereview?placeid=${locationData.gbp_place_id}`}
        downloadFileName={`${locationData.name}__GBP新規口コミ投稿URL_QR-CODE`}
      />
    </div>
  );
};

export default BasicProfile;
