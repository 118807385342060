import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

export interface IStatusItemProps {
  status: string;
}

export const StatusItem: React.FunctionComponent<IStatusItemProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`customizedReviewsAnalysisPage.status.${key}`);

  const { status } = props;

  // get Status Div
  const getStatusDiv = () => {
    switch (status) {
      case "success":
        return (
          <div className="status-wrap">
            <i className="icons icon-done"></i>
            <span className="txt green">{t("success")}</span>
          </div>
        );
      case "not_enough_reviews":
        return (
          <div className="status-wrap">
            <i className="icons icon-error"></i>
            <span className="txt red">{t("not_enough_reviews")}</span>
          </div>
        );
      case "gemini_error":
        return (
          <div className="status-wrap">
            <i className="icons icon-wait"></i>
            <span className="txt wait">{t("gemini_error")}</span>
          </div>
        );
      case "gemini_bad_json":
        return (
          <div className="status-wrap">
            <i className="icons icon-process"></i>
            <span className="txt orange">{t("gemini_bad_json")}</span>
          </div>
        );
    }
  };

  return <>{getStatusDiv()}</>;
};

export default StatusItem;
