import * as React from "react";
import ReactGA from "react-ga4";

import { useMe } from "../../../hooks/queries";
import { AccountType } from "../../../models/AccountData";
import { FeatureUseLimitCalculator } from "../../../models/featureUseLimits/FeatureUseLimitsCalculator";
import { OrgFeatures } from "../../../models/featureUseLimits/FeatureUseLimitsData.const";

interface ICreateLocalPostButtonProps {
  onClickCreatePostToGoogleBusinessProfile: () => void;
  innerText: string;
  gbpLink: string;
}

export const CreateLocalPostButton: React.FunctionComponent<
  ICreateLocalPostButtonProps
> = (props) => {
  const { onClickCreatePostToGoogleBusinessProfile, innerText, gbpLink } =
    props;

  const { data: me } = useMe();

  return me?.type === AccountType.ADMIN ||
    me?.type === AccountType.LOC_ADMIN ||
    (me?.type === AccountType.ORG_ADMIN &&
      FeatureUseLimitCalculator.getFeatureUseStatus(
        OrgFeatures.USE_LOCAL_POST,
        me.organization.commonFeatureUseLimit
      )) ? (
    <button
      className="btn btn-border"
      onClick={onClickCreatePostToGoogleBusinessProfile}
    >
      {innerText}
    </button>
  ) : (
    <a
      href={gbpLink}
      className="btn btn-border"
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        ReactGA.event({
          category: "GBP Link",
          action: "Click to GBP",
          label: "localPosts",
        });
      }}
    >
      {innerText}
    </a>
  );
};
