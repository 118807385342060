import { z } from "zod";

import i18n from "../../../../i18n";
import { LocationData } from "../../../../models/LocationData";

const t = (key: string) =>
  i18n.t(`facilityDetailsPage.modalEditProfile.businessNameForm.errors.${key}`);

export const schema = z.object({
  name: z.string().trim().min(1, t("businessName.required"))
});

export type Schema = z.infer<typeof schema>;

export const generateDefaultValues = (data: LocationData): Schema => {
  return {
    name: data.name
  };
};
