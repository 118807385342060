import React from "react";
import ReactDatePicker from "react-datepicker";
import {
  Controller,
  Path,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import Checkbox from "../../../FormElement/Checkbox";
import { TimeOfDayInput } from "../../../FormElement/TimeOfDay";
import { SpecialOperationHours } from "../types";

import "./styles.scss";

export interface IHookFormSpecialOperationHoursIndividualProps {
  dateIndex: number;
}

export const HookFormSpecialOperationHoursIndividual = ({
  dateIndex,
}: IHookFormSpecialOperationHoursIndividualProps) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(
      `facilityDetailsPage.modalEditProfile.editProfileForm.specialHours.${key}`
    );

  const { control, watch } = useFormContext<SpecialOperationHours>();
  const name: Path<SpecialOperationHours> = `specialHourPeriods.${dateIndex}`;

  const isOpen = !watch(`${name}.closed`);

  const periods = useFieldArray({
    control,
    name: `${name}.periods`,
  });

  const handleAddPeriod = () => {
    periods.append({
      openTime: {
        hours: 0,
        minutes: 0,
      },
      closeTime: {
        hours: 0,
        minutes: 0,
      },
    });
  };

  return (
    <div className="individual-special-operation-hours">
      <div className="individual-special-operation-hours-date">
        <Controller
          control={control}
          name={`${name}.startDate`}
          render={({ field }) => (
            <ReactDatePicker
              className="individual-special-operation-hours-date-input"
              locale="ja"
              selected={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name={`${name}.closed`}
          render={({ field }) => (
            <Checkbox
              checked={field.value}
              id={`${name}-closed`}
              label={t("closed")}
              onChange={() => field.onChange(!field.value)}
            />
          )}
        />
      </div>
      {isOpen && (
        <div className="individual-special-operation-hours-time-periods">
          {periods.fields.map((period, index) => (
            <div
              key={period.id}
              className="individual-special-operation-hours-time-period"
            >
              <Controller
                control={control}
                name={`${name}.periods.${index}.openTime`}
                render={({ field }) => (
                  <TimeOfDayInput
                    borderStyle="open"
                    timeCaption={t("openTime")}
                    time={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <span className="individual-special-operation-hours-separator">
                ~
              </span>
              <Controller
                control={control}
                name={`${name}.periods.${index}.closeTime`}
                render={({ field }) => (
                  <TimeOfDayInput
                    borderStyle="close"
                    timeCaption={t("closeTime")}
                    time={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              {index === 0 ? (
                <button
                  className="individual-special-operation-hours-add-button btn"
                  type="button"
                  onClick={handleAddPeriod}
                ></button>
              ) : (
                <button
                  className="individual-special-operation-hours-remove-button btn"
                  type="button"
                  onClick={() => periods.remove(index)}
                ></button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
