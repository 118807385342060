import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import DataSvc from "../../../../services/dataSvc";
import { ImpersonateOnly } from "../../../Common/ImpersonateOnly";

import "./styles.scss";

interface IAIGenerateButtonProps {
  url: string;
  onGenerate: (text: string) => void;
  adminOnly?: boolean;
}

export const AIGenerateButton = (props: IAIGenerateButtonProps) => {
  const { url, onGenerate, adminOnly = false } = props;

  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`hookForm.textarea.aiGenerateButton.${key}`);

  const handleGenerate = useCallback(async () => {
    try {
      const response = await DataSvc.generateAiText(url);
      // Simple fail-safe in case a non-string response is returned.
      if (typeof response === "string") {
        // Gemini sometimes returns a string with a newline character at the end. Trim it off for UX.
        onGenerate(response.trim());
      }
    } catch {
      // Do nothing, axios will handle the error
    }
  }, [url, onGenerate]);

  return (
    <ImpersonateOnly enabled={adminOnly}>
      <button
        className="hook-form-ai-generate-button btn btn-border"
        type="button"
        onClick={handleGenerate}
      >
        {t("generate")}
      </button>
    </ImpersonateOnly>
  );
};
