import React from "react";
import { useTranslation } from "react-i18next";

import { UseLimitsFilterConditionData } from "../../../models/featureUseLimits/FeatureUselimitFilterConditionData";

import "./styles.scss";

export interface ICurrentFiltersProps {
  conditionFormData: UseLimitsFilterConditionData;
  onClickClearFilterCondition?: () => void;
}

export const CurrentFilters: React.FunctionComponent<ICurrentFiltersProps> = ({
  conditionFormData,
  onClickClearFilterCondition,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`functionManagementPage.${key}`);

  return (
    <div className="current-use-limit-filters">
      <div className="current-filters-top">
        <h3>{t("listTopBar.active_filters")}</h3>
        {onClickClearFilterCondition && (
          <button
            className="btn btn-border"
            onClick={(event) => {
              onClickClearFilterCondition();
              event.preventDefault();
            }}
          >
            {t("listTopBar.clear_condition")}
          </button>
        )}
      </div>
      <div className="label-area">
        {conditionFormData.useLimitsStatus.length > 0 &&
          conditionFormData.useLimitsStatus.map((item) => [
            <div className="left-txt" key="labels-left">
              {t(`modalFilterCondition.${item.name}.label`)} :
            </div>,
            <div className="labels" key="labels-labels">
              {t(
                `modalFilterCondition.${item.name}.${
                  item.can_use ? "true" : "false"
                }`
              )}
            </div>,
          ])}
      </div>
    </div>
  );
};
