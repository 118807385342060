export enum FeatureTypes {
  USER = "user",
  ORG = "org",
  LOC = "loc"
}

export enum UseLimitCycleType {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year"
}

export enum OrgFeatures {
  CAN_USE_MASS_POST = "can_use_mass_post",
  RECEIVE_ORG_REPORTS = "receive_org_reports",
  USE_LOCAL_POST = "use_local_post"
}

export enum LocFeatures {
  VIEW_REVIEW_SUMMARIES = "view_review_summaries"
}

export const AllFeatures = { ...OrgFeatures, ...LocFeatures };
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AllFeatures = OrgFeatures | LocFeatures;

export enum FeatureUseStatus {
  CAN_USE = "CAN_USE",
  CANNOT_USE = "CANNOT_USE",
  LIMIT_REACHED = "LIMIT_REACHED",
  EXPIRED = "EXPIRED"
}
