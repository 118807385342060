import DemoModeSvc from "./demoModeSvc";
import { AccountData } from "../models/AccountData";
import { FeatureDataConverter } from "../models/featureUseLimits/FeatureUseLimitsConverter";
import { LocationData } from "../models/LocationData";
import { OrganizationData } from "../models/OrganizationData";

const demoMode = DemoModeSvc.current;

// Class to use when manipulating data from the API to fit the front-end model
export class ApiDataConverter {
  static processAccountData(data: AccountData): AccountData {
    const demoModeProcessedAccount = demoMode.isDemoModeOn
      ? demoMode.processAccount(data)
      : data;

    const convertFeatureUseLimits = FeatureDataConverter.convertUserData(
      demoModeProcessedAccount
    );

    return convertFeatureUseLimits;
  }

  static processOrganizationData(data: OrganizationData): OrganizationData {
    const demoModeProcessedOrganization = demoMode.isDemoModeOn
      ? demoMode.processOrganization(data)
      : data;

    const convertFeatureUseLimits =
      FeatureDataConverter.convertOrganizationData(
        demoModeProcessedOrganization
      );

    return convertFeatureUseLimits;
  }

  static processLocationData(data: LocationData): LocationData {
    const demoModeProcessedLocation = demoMode.isDemoModeOn
      ? demoMode.processLocation(data)
      : data;

    const convertFeatureUseLimits = FeatureDataConverter.convertLocationData(
      demoModeProcessedLocation
    );

    return convertFeatureUseLimits;
  }
}
