import React, { useMemo } from "react";
import ReactDatePicker from "react-datepicker";

import { TimeOfDay } from "./types";

import "./styles.scss";

interface ITimeOfDayInputProps {
  time: TimeOfDay | undefined;
  onChange: (time: TimeOfDay) => void;
  borderStyle?: "normal" | "open" | "close";
  disabled?: boolean;
  timeCaption?: string;
}

export const TimeOfDayInput = ({
  time,
  onChange,
  disabled,
  borderStyle = "normal",
  timeCaption,
}: ITimeOfDayInputProps) => {
  const date = useMemo(() => {
    const d = new Date();
    d.setHours(time?.hours ?? 0);
    d.setMinutes(time?.minutes ?? 0);
    return d;
  }, [time]);

  const handleChange = (date: Date | null) => {
    onChange({
      hours: date?.getHours() ?? 0,
      minutes: date?.getMinutes() ?? 0,
    });
  };

  return (
    <div className={`time-of-day-input ${borderStyle}`}>
      <ReactDatePicker
        className={borderStyle}
        locale="ja"
        selected={date}
        onChange={handleChange}
        showTimeSelectOnly
        showTimeSelect
        timeCaption={timeCaption}
        dateFormat="HH:mm"
        disabled={disabled}
        preventOpenOnFocus
      />
    </div>
  );
};
