import { z } from "zod";

const timeOfDaySchema = z.object({
  hours: z.coerce.number().min(0).max(23).optional(),
  minutes: z.coerce.number().min(0).max(59).optional()
});

const specialHourPeriodSchema = z.object({
  startDate: z.date(),
  periods: z.array(
    z.object({
      openTime: timeOfDaySchema.optional(),
      closeTime: timeOfDaySchema.optional()
    })
  ),
  closed: z.boolean()
});

export const schema = z.object({
  specialHourPeriods: z.array(specialHourPeriodSchema)
});

export type Schema = z.infer<typeof schema>;

const googleDateSchema = z.object({
  year: z.number(),
  month: z.number(),
  day: z.number()
});

const apiSpecialHourPeriodSchema = z.object({
  startDate: googleDateSchema,
  endDate: googleDateSchema.optional(),
  openTime: timeOfDaySchema.optional(),
  closeTime: timeOfDaySchema.optional(),
  closed: z.boolean().optional()
});

export const apiSchema = z.object({
  specialHourPeriods: z.array(apiSpecialHourPeriodSchema).optional()
});

export type ApiSchema = z.infer<typeof apiSchema>;
