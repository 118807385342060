import React, { createContext, useState } from "react";

type RegisterParams = {
  key: string;
  onCloseCallback?: () => void;
  message?: string;
};

export const ConfirmCloseContext = createContext<{
  register: (params: RegisterParams) => void;
  unregister: (key: string) => void;
  getRegistrations: (key?: string) => RegisterParams[];
  closeAll: () => void;
}>({
  register: () => {},
  unregister: () => {},
  getRegistrations: () => [],
  closeAll: () => {},
});

export const ConfirmCloseContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [registrations, setRegistrations] = useState<RegisterParams[]>([]);

  const register = (params: RegisterParams) => {
    setRegistrations((prev) => {
      if (prev.find((r) => r.key === params.key)) {
        return prev;
      }
      return [...prev, params];
    });
  };

  const unregister = (key: string) => {
    setRegistrations((prev) => {
      // If the key is not found, return previous state.
      // This explicit check is to prevent infinite re-render loops.
      if (prev.find((r) => r.key === key)) {
        return prev.filter((r) => r.key !== key);
      }
      return prev;
    });
  };

  const getRegistrations = (key?: string): RegisterParams[] => {
    if (registrations.length === 0) {
      return [];
    }
    if (key) {
      const registration = registrations.find((r) => r.key === key);
      return registration ? [registration] : [];
    }
    return registrations;
  };

  const callAllOnCloseCallbacks = () => {
    registrations.forEach((r) => {
      r.onCloseCallback?.();
    });
  };

  const closeAll = () => {
    callAllOnCloseCallbacks();
    setRegistrations([]);
  };

  return (
    <ConfirmCloseContext.Provider
      value={{
        register,
        unregister,
        getRegistrations: getRegistrations,
        closeAll,
      }}
    >
      {children}
    </ConfirmCloseContext.Provider>
  );
};
