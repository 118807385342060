import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { HookFormSpecialOperationHoursIndividual } from "./Individual";
import { SpecialOperationHours } from "./types";

import "./styles.scss";

export const HookFormSpecialOperationHours = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(
      `facilityDetailsPage.modalEditProfile.editProfileForm.specialHours.${key}`
    );

  const { control } = useFormContext<SpecialOperationHours>();

  const specialHourPeriods = useFieldArray({
    control,
    name: "specialHourPeriods",
  });

  const handleAddSpecialHourPeriod = () => {
    specialHourPeriods.append({
      startDate: new Date(),
      periods: [
        {
          openTime: { hours: 0, minutes: 0 },
          closeTime: { hours: 0, minutes: 0 },
        },
      ],
      closed: false,
    });
  };

  return (
    <div>
      <div className="special-operation-hours-current">
        <h3>{t("current.title")}</h3>
        {specialHourPeriods.fields.map((field, index) => (
          <div className="special-operation-hours-current-item" key={field.id}>
            <HookFormSpecialOperationHoursIndividual dateIndex={index} />
            <button
              type="button"
              className="btn btn-border btn-border-red"
              onClick={() => specialHourPeriods.remove(index)}
            >
              {t("remove_date")}
            </button>
          </div>
        ))}
        <div>
          <button
            type="button"
            className="btn btn-border"
            onClick={handleAddSpecialHourPeriod}
          >
            {t("add_date")}
          </button>
        </div>
      </div>
      <hr />
      <div className="special-operation-hours-multiple-days">
        <h3>{t("multipleDays.title")}</h3>
        <div>開発予定</div>
      </div>
    </div>
  );
};
