import React from "react";
import { useFormContext } from "react-hook-form";

import "./styles.scss";
import { HookFormError } from "../Error";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

export const Input = (props: InputProps) => {
  const { register, formState } = useFormContext();

  return (
    <>
      <input className="hook-form-input" {...props} {...register(props.name)} />
      <div className="hook-form-status-display">
        <HookFormError
          error={formState.errors[props.name]?.message?.toString()}
        />
      </div>
    </>
  );
};
