import React from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

import { HookFormCancelButton } from "../CancelButton";
import { HookFormSubmitButton } from "../SubmitButton";

import "./styles.scss";

interface IHookFormProps<T extends FieldValues>
  extends React.ComponentProps<"form"> {
  children: React.ReactNode;
  form: UseFormReturn<T>;
  onSubmit: (data: any) => void;
  submitButtonLabel?: string;
  showCancelButton?: boolean;
  cancelButtonLabel?: string;
  onCancel?: () => void;
}

export const HookForm = <T extends FieldValues>({
  children,
  className,
  form,
  onSubmit,
  submitButtonLabel,
  showCancelButton,
  cancelButtonLabel,
  onCancel,
  ...rest
}: IHookFormProps<T>) => {
  return (
    <FormProvider {...form}>
      <form
        className={`hook-form ${className || ""}`}
        onSubmit={form.handleSubmit(onSubmit)}
        {...rest}
      >
        {children}
        <div className="buttons">
          {showCancelButton && (
            <HookFormCancelButton
              label={cancelButtonLabel}
              onClick={onCancel}
            />
          )}
          <HookFormSubmitButton label={submitButtonLabel} />
        </div>
      </form>
    </FormProvider>
  );
};
