import { z } from "zod";

import { LocationData } from "../../../../models/LocationData";

const updateSocialLinkSchema = z.object({
  url: z.string().url().nullish()
});

export const schema = z.object({
  facebook: updateSocialLinkSchema,
  pinterest: updateSocialLinkSchema,
  youtube: updateSocialLinkSchema,
  instagram: updateSocialLinkSchema,
  linkedin: updateSocialLinkSchema,
  twitter: updateSocialLinkSchema,
  tiktok: updateSocialLinkSchema
});

export type Schema = z.infer<typeof schema>;

export const generateDefaultValues = (data: LocationData): Schema => {
  return {
    facebook: data.social_profiles.facebook,
    pinterest: data.social_profiles.pinterest,
    youtube: data.social_profiles.youtube,
    instagram: data.social_profiles.instagram,
    linkedin: data.social_profiles.linkedin,
    twitter: data.social_profiles.twitter,
    tiktok: data.social_profiles.tiktok
  };
};
