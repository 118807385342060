/**
 * Social platforms that are currently supported.
 */
export const SocialPlatforms = [
  "facebook",
  "pinterest",
  "youtube",
  "instagram",
  "linkedin",
  "twitter",
  "tiktok"
] as const;

export type SocialLinks = {
  [key in (typeof SocialPlatforms)[number]]: { url: string | null };
};
