import React from "react";
import { useTranslation } from "react-i18next";

import { CategoryData } from "../../../models/CategoryData";
import { LocationData } from "../../../models/LocationData";

import "./styles.scss";

export interface ICategoriesDisplayProps {
  locationData: LocationData;
}

export const CategoriesDisplay: React.FunctionComponent<
  ICategoriesDisplayProps
> = (props) => {
  const { locationData } = props;

  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityDetailsPage.categoriesDisplay.${key}`);

  let mainCategory: CategoryData | undefined;
  const subCategories: CategoryData[] = [];

  locationData.categories.forEach((category) => {
    if (category.is_main) {
      mainCategory = category;
    } else {
      subCategories.push(category);
    }
  });
  return (
    <div className="categories-container">
      <div className="main-category">
        <div className="category-container">
          <span className="category-name">{mainCategory?.name}</span>
          <span className="gbp-category-name">
            {mainCategory?.gbp_category_name}
          </span>
        </div>
      </div>
      <div className="sub-categories">
        <span className="category-title">{t("sub_categories")}</span>
        {subCategories.map((category) => (
          <div className="category-container" key={category.gbp_category_id}>
            <span className="category-name">{category.name}</span>
            <span className="gbp-category-name">
              {category.gbp_category_name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
