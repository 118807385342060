import { z } from "zod";

import i18n from "../../../../i18n";
import { LocationData } from "../../../../models/LocationData";
import { PROFILE_MAX_LENGTH } from "../const";

const t = (key: string) =>
  i18n.t(`facilityDetailsPage.modalEditProfile.editProfileForm.errors.${key}`);

export const schema = z.object({
  description: z
    .string()
    .max(PROFILE_MAX_LENGTH, t("profile.description.too_big"))
    .optional()
});

export type Schema = z.infer<typeof schema>;

export const generateDefaultValues = (data: LocationData): Schema => {
  return {
    description: data.profile ?? ""
  };
};
