import React from "react";
import { useTranslation } from "react-i18next";

interface IHookFormCancelButtonProps {
  className?: string;
  label?: string;
  onClick?: () => void;
}

export const HookFormCancelButton = ({
  className,
  label,
  onClick,
}: IHookFormCancelButtonProps) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`hookForm.cancelButton.${key}`);
  return (
    <button
      className={`btn btn-border ${className}`}
      type="button"
      onClick={onClick}
    >
      {label || t("cancel")}
    </button>
  );
};
