import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DayOfWeek } from "../../../../models/DayOfWeek";
import Checkbox from "../../../FormElement/Checkbox";
import { TimeOfDayInput } from "../../../FormElement/TimeOfDay";
import { HookFormError } from "../../Error";
import { OperationHours } from "../types";

import "./styles.scss";

interface IHookFormOperationHoursDailyProps {
  day: DayOfWeek;
}

export const HookFormOperationHoursDaily = ({
  day,
}: IHookFormOperationHoursDailyProps) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(
      `facilityDetailsPage.modalEditProfile.editProfileForm.regularHours.${key}`
    );

  const { watch, control, formState } = useFormContext<OperationHours>();

  const isOpen = watch(`${day}.isOpen`);

  const timePeriods = useFieldArray({
    control: control,
    name: `${day}.timePeriods`,
  });

  return (
    <div className="daily-operation-hours">
      <div className="daily-operation-hours-inputs">
        <div className="daily-operation-hours-checkbox-container">
          <label htmlFor={day}>{t(`daysOfTheWeek.${day}`)}</label>
          <Controller
            control={control}
            name={`${day}.isOpen`}
            render={({ field }) => (
              <Checkbox
                checked={!field.value}
                id={day}
                label={t("closed")}
                onChange={() => field.onChange(!field.value)}
              />
            )}
          />
        </div>
        {isOpen && (
          <div className="daily-operation-hours-time-periods">
            {timePeriods.fields.map((timePeriod, index) => (
              <div
                className="daily-operation-hours-time-period"
                key={timePeriod.id}
              >
                <div className="daily-operation-hours-time-period-inputs">
                  <Controller
                    control={control}
                    name={`${day}.timePeriods.${index}.openTime`}
                    rules={{ deps: `${day}.timePeriods.${index}.closeTime` }}
                    render={({ field }) => (
                      <TimeOfDayInput
                        borderStyle="open"
                        disabled={!isOpen}
                        timeCaption={t("openTime")}
                        time={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <span className="daily-operation-hours-separator">~</span>
                  <Controller
                    control={control}
                    name={`${day}.timePeriods.${index}.closeTime`}
                    render={({ field }) => (
                      <TimeOfDayInput
                        borderStyle="close"
                        disabled={!isOpen}
                        time={field.value}
                        timeCaption={t("closeTime")}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  {index === 0 ? (
                    <button
                      className="daily-operation-hours-add-button btn"
                      type="button"
                      onClick={() =>
                        timePeriods.append({
                          openTime: { hours: 0, minutes: 0 },
                          closeTime: { hours: 0, minutes: 0 },
                        })
                      }
                    ></button>
                  ) : (
                    <button
                      className="daily-operation-hours-remove-button btn"
                      type="button"
                      onClick={() => timePeriods.remove(index)}
                    ></button>
                  )}
                </div>
                {formState.errors?.[day]?.timePeriods?.[index]?.closeTime && (
                  <HookFormError
                    error={
                      formState.errors?.[day]?.timePeriods?.[index]?.closeTime
                        ?.message
                    }
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
