/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { getArrangedUselimits } from "./utils";
import FunctionIndividualSettingList from "../../components/FunctionManagementPage/IndividualSettingList";
import { ModalEditUseLimit } from "../../components/FunctionManagementPage/ModalEditUseLimit";
import ModalFilterCondition from "../../components/FunctionManagementPage/ModalFilterCondition";
import { ModalConfirm } from "../../components/ModalConfirm";
import { useMe } from "../../hooks/queries";
import { canUseBooleanData } from "../../models/featureUseLimits/FeatureUselimitFilterConditionData";
import { ArrangedUseLimitsData } from "../../models/featureUseLimits/FeatureUseLimitsData";
import { FeatureTypes } from "../../models/featureUseLimits/FeatureUseLimitsData.const";
import DataSvc from "../../services/dataSvc";
import "./styles.scss";

export interface IUseLimitsFilterFormData {
  searchKeyword: string;
  numberPerPage: number;
  pageIndex: number;
  useLimitsStatus: canUseBooleanData[];
}

type IFunctionManagementPageProps = RouteComponentProps<any>;

const FunctionManagementPage: React.FunctionComponent<
  IFunctionManagementPageProps
> = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`functionManagementPage.${key}`);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: me } = useMe();

  const [targetType, setTargetType] = useState<FeatureTypes>(FeatureTypes.ORG);
  const [assignUseLimitData, setAssignUseLimitData] = useState<any>();
  const [showModalEditFeatures, setShowModalEditFeatures] =
    useState<boolean>(false);
  const [showModalConfirmDeleteUseLimits, setShowModalConfirmDeleteUseLimits] =
    useState<boolean>(false);
  const [
    showModalConfirmClearFilterCondition,
    setShowModalConfirmClearFilterCondition,
  ] = useState<boolean>(false);
  const [shownModalFilterCondition, setShownModalFilterCondition] =
    useState<boolean>(false);

  const defaultFilterFormData = {
    searchKeyword: "",
    numberPerPage: 20,
    pageIndex: 1,
    useLimitsStatus: [],
  };

  const [filterFormData, setFilterFormData] =
    useState<IUseLimitsFilterFormData>(defaultFilterFormData);

  const { data: featuresData } = useQuery(["features", targetType], () => {
    return DataSvc.getFeatures({ target: targetType });
  });

  useEffect(() => {
    if (featuresData) {
      setFilterFormData({
        ...defaultFilterFormData,
        useLimitsStatus: featuresData
          .filter((item: any) => !item.is_metered)
          .map((feature: any) => {
            return { id: feature.id, can_use: undefined, name: feature.name };
          }),
      });
    }
  }, [featuresData]);

  const resetFilterFormData = () => {
    setFilterFormData({
      ...defaultFilterFormData,
      useLimitsStatus: featuresData
        .filter((item: any) => !item.is_metered)
        .map((feature: any) => {
          return { id: feature.id, can_use: undefined, name: feature.name };
        }),
    });
  };

  const { data: useLimitsData, refetch: refetchingUseLimits } = useQuery(
    ["features-use-limits", targetType, filterFormData],
    () => {
      return DataSvc.getUseLimits({
        keyword: filterFormData.searchKeyword,
        limit: filterFormData.numberPerPage,
        offset: (filterFormData.pageIndex - 1) * filterFormData.numberPerPage,
        target: targetType,
        ...(filterFormData.useLimitsStatus.length > 0 && {
          conditions: JSON.stringify(
            filterFormData.useLimitsStatus.filter(
              (item: any) => item.can_use !== undefined
            )
          ),
        }),
      });
    }
  );

  const deleteUseLimits = useMutation(() => {
    return DataSvc.deleteUseLimits(
      assignUseLimitData.id,
      assignUseLimitData.target ?? FeatureTypes.ORG
    );
  });

  const [arrangedUseLimitsData, setArrangedUseLimitsData] = useState<{
    arrangedUseLimits: ArrangedUseLimitsData[];
    totalCount: number;
  }>({ arrangedUseLimits: [], totalCount: 0 });

  useEffect(() => {
    if (useLimitsData) {
      const arrangedUseLimits = getArrangedUselimits(
        useLimitsData.list,
        targetType
      );
      setArrangedUseLimitsData({
        arrangedUseLimits: arrangedUseLimits,
        totalCount: useLimitsData.totalCount,
      });
    }
  }, [useLimitsData]);

  const switchingTargertType: React.MouseEventHandler = (event) => {
    setTargetType(
      event.currentTarget.getAttribute("data-target-type") as FeatureTypes
    );
    setFilterFormData(defaultFilterFormData);
    setArrangedUseLimitsData({ arrangedUseLimits: [], totalCount: 0 });
  };

  const onDeleteUseLimits = async () => {
    const data = await deleteUseLimits.mutateAsync();
    if (data.status === 200) {
      setShowModalConfirmDeleteUseLimits(false);
      setShowModalEditFeatures(false);
      refetchingUseLimits();
    }
  };

  return (
    <>
      <div className="right-content function-management">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{`${t("function_management")} (${t(
            targetType
          )})`}</div>
          <div className="right-btns">
            {[FeatureTypes.ORG, FeatureTypes.USER, FeatureTypes.LOC]
              .filter((item) => item !== targetType)
              .map((item) => (
                <button
                  className="right btn btn-blue"
                  onClick={switchingTargertType}
                  data-target-type={item}
                  key={item}
                >
                  {t(`switch_to_${item}_features`)}
                </button>
              ))}
          </div>
          <div className="line"></div>
        </div>

        <div className="individual-settings">{t(targetType)}</div>
        {!!arrangedUseLimitsData && (
          <FunctionIndividualSettingList
            arrangedUseLimits={arrangedUseLimitsData.arrangedUseLimits}
            targetType={targetType}
            totalCount={arrangedUseLimitsData.totalCount}
            filterFormData={filterFormData}
            onChangeFilterFormData={(filterFormData: any) => {
              setFilterFormData(filterFormData);
            }}
            onClickEdit={(useLimit: ArrangedUseLimitsData) => {
              setAssignUseLimitData(useLimit);
              setShowModalEditFeatures(true);
            }}
            onClickFilter={() => {
              setShownModalFilterCondition(true);
            }}
            onClickClearFilterCondition={() => {
              setShowModalConfirmClearFilterCondition(true);
            }}
          />
        )}

        {showModalEditFeatures && (
          <ModalEditUseLimit
            arrangeUseLimits={assignUseLimitData}
            onClose={() => {
              setShowModalEditFeatures(false);
            }}
            onRefresh={() => {
              refetchingUseLimits();
            }}
          />
        )}

        {showModalConfirmDeleteUseLimits && (
          <ModalConfirm
            title={"please_confirm"}
            cancelLabel={"cancel"}
            confirmLabel={"confirm"}
            onClose={() => {
              setShowModalConfirmDeleteUseLimits(false);
            }}
            onConfirm={() => {
              onDeleteUseLimits();
              setShowModalConfirmDeleteUseLimits(false);
            }}
          />
        )}

        {showModalConfirmClearFilterCondition && (
          <ModalConfirm
            title={"please_confirm_to_reset"}
            cancelLabel={"cancel"}
            confirmLabel={"confirm"}
            onClose={() => {
              setShowModalConfirmClearFilterCondition(false);
            }}
            onConfirm={() => {
              resetFilterFormData();
              setShowModalConfirmClearFilterCondition(false);
            }}
          />
        )}

        {shownModalFilterCondition && (
          <ModalFilterCondition
            onClose={() => {
              setShownModalFilterCondition(false);
            }}
            filterFormData={filterFormData}
            onApply={(filterFormData: IUseLimitsFilterFormData) => {
              setFilterFormData(filterFormData);
              refetchingUseLimits();
              setShownModalFilterCondition(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(FunctionManagementPage);
