import { zodResolver } from "@hookform/resolvers/zod";
import React, { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { generateDefaultValues, schema, Schema } from "./schema";
import useRefreshLocationData, {
  RefreshType,
} from "../../../../containers/FacilityDetailsPage/hooks/useRefreshLocationData";
import { ConfirmCloseContext } from "../../../../contexts/confirmClose";
import { LocationData } from "../../../../models/LocationData";
import DataSvc from "../../../../services/dataSvc";
import { HookForm } from "../../../HookForm/HookForm";
import { Input } from "../../../HookForm/Input";

export interface IBusinessNameSectionProps {
  locationData: LocationData;
  accordionEventKey: string;
}

export const BusinessNameSection = (props: IBusinessNameSectionProps) => {
  const { locationData, accordionEventKey } = props;

  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityDetailsPage.modalEditProfile.businessNameForm.${key}`);

  const refresh = useRefreshLocationData(locationData.id.toString());

  const defaultValues = useMemo(() => {
    return generateDefaultValues(locationData);
  }, [locationData]);

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data: Schema) => {
    const payload = {
      location: {
        title: data.name,
      },
    };
    try {
      await DataSvc.updateLocationProfile(locationData.id, payload);
      toast.success(t("success"));
      refresh(RefreshType.LOCATION);
      form.reset(data);
    } catch (error) {
      // Do nothing, axios will handle the error
    }
  };

  const { register, unregister } = useContext(ConfirmCloseContext);

  useEffect(() => {
    if (form.formState.isDirty) {
      register({
        key: accordionEventKey,
        message: "dirty",
        onCloseCallback: form.reset,
      });
    } else {
      unregister(accordionEventKey);
    }
  }, [form.formState.isDirty, register, unregister, accordionEventKey, form]);

  return (
    <HookForm onSubmit={onSubmit} form={form} submitButtonLabel={t("update")}>
      <Input name="name" />
      <p dangerouslySetInnerHTML={{ __html: t("warning") }} />
    </HookForm>
  );
};
