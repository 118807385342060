import dayjs from "dayjs";

import { ApiSchema, apiSchema, Schema } from "./schema";
import { LocationData } from "../../../../models/LocationData";

export const generateDefaultValues = (data: LocationData): Schema => {
  return convertToClient(data.special_hours);
};

const convertToClient = (data?: string): Schema => {
  const specialHours = data ? JSON.parse(data) : {};
  const apiData = apiSchema.parse(specialHours);

  const clientData: Schema = {
    specialHourPeriods: []
  };

  // Group by date for better UX
  apiData.specialHourPeriods?.forEach((period) => {
    const date = dayjs()
      .set("year", period.startDate.year)
      .set("month", period.startDate.month - 1)
      .set("date", period.startDate.day)
      .toDate();
    const existingPeriod = clientData.specialHourPeriods.find((p) =>
      isSameDay(p.startDate, date)
    );
    if (existingPeriod) {
      existingPeriod.periods.push(period);
    } else {
      clientData.specialHourPeriods.push({
        startDate: date,
        periods: [
          {
            openTime: period.openTime ?? { hours: 0, minutes: 0 },
            closeTime: period.closeTime ?? { hours: 0, minutes: 0 }
          }
        ],
        closed: period.closed ?? false
      });
    }
  });

  return clientData;
};

export const convertToApi = (data: Schema): ApiSchema => {
  const specialHourPeriods: ApiSchema["specialHourPeriods"] = [];

  data.specialHourPeriods.forEach((period) => {
    const date = {
      year: period.startDate.getFullYear(),
      month: period.startDate.getMonth() + 1,
      day: period.startDate.getDate()
    };
    period.periods.forEach((p) => {
      specialHourPeriods.push({
        startDate: date,
        endDate: date,
        openTime: p.openTime,
        closeTime: p.closeTime,
        closed: period.closed
      });
    });
  });

  return { specialHourPeriods };
};

function isSameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
