import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useUpdateImpersonatedOrganizationContext } from "./organizationImpersonation";
import { useMe } from "./queries";
import { AccountData, AccountType } from "../models/AccountData";

export const canAccessOrganization = (
  organizationId: string | number,
  me?: AccountData
): boolean => {
  // Pass admins
  if (me?.type === "admin") {
    return true;
  }

  // User has no organization
  if (!me?.organization) {
    return false;
  }

  if (String(organizationId) === me?.organization?.id) {
    return true;
  }

  return false;
};

export const useValidateOrganization = (props: {
  matchOrganizationId: any | undefined;
  redirectUrlFormat: (template: string) => string;
  setOrganizationId?: (organizationId: string) => void;
}) => {
  const { matchOrganizationId, setOrganizationId, redirectUrlFormat } = props;

  const history = useHistory();
  const { data: me } = useMe();

  const updateImpersonatedOrganizationContext =
    useUpdateImpersonatedOrganizationContext();

  useEffect(() => {
    if (!me) {
      return;
    }

    if (me?.type === AccountType.LOC_ADMIN) {
      // Redirect to home
      history.push("");
      return;
    }

    if (canAccessOrganization(matchOrganizationId, me)) {
      if (setOrganizationId) {
        setOrganizationId(matchOrganizationId);
      }
      updateImpersonatedOrganizationContext(matchOrganizationId);
    } else {
      history.push(`${redirectUrlFormat(me.organization.id)}`);
    }

    return () => {
      updateImpersonatedOrganizationContext(null);
    };
  }, [
    matchOrganizationId,
    me,
    history,
    setOrganizationId,
    redirectUrlFormat,
    updateImpersonatedOrganizationContext
  ]);
};
