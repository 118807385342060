import React from "react";
import { useFormContext } from "react-hook-form";

import { AIGenerateButton } from "./AIGenerateButton";
import { HookFormError } from "../Error";

import "./styles.scss";

interface IHookFormTextareaProps extends React.ComponentProps<"textarea"> {
  name: string;
  maxChars?: number;
  showChars?: boolean;
  aiGenerateUrl?: string;
  aiGenerateAdminOnly?: boolean;
}

export const HookFormTextarea = (props: IHookFormTextareaProps) => {
  const {
    name,
    className,
    showChars,
    maxChars,
    aiGenerateUrl,
    aiGenerateAdminOnly,
    ...rest
  } = props;

  const { register, formState, watch, setValue } = useFormContext();
  const value = watch(name);

  const handleAiGenerate = (text: string) => {
    setValue(name, text, { shouldDirty: true });
  };

  return (
    <div className={`hook-form-textarea ${className || ""}`}>
      <div className="hook-form-textarea-container">
        <textarea {...register(name)} {...rest} />
        {aiGenerateUrl && (
          <AIGenerateButton
            url={aiGenerateUrl}
            onGenerate={handleAiGenerate}
            adminOnly={aiGenerateAdminOnly}
          />
        )}
      </div>
      <div className="hook-form-status-display">
        <HookFormError error={formState.errors[name]?.message?.toString()} />
        {showChars && (
          <span
            className={`hook-form-status-display-chars ${
              maxChars && value.length > maxChars ? "over" : ""
            }`}
          >
            {`${value.length} ${maxChars ? `/ ${maxChars}` : ""}`}
          </span>
        )}
      </div>
    </div>
  );
};
