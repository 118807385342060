import dayjs from "dayjs";

import { CommonFeatureUseLimitData } from "./FeatureUseLimitsConverter";
import {
  FeatureTypes,
  AllFeatures,
  FeatureUseStatus
} from "./FeatureUseLimitsData.const";

export class FeatureUseLimitCalculator {
  static getFeatureUseStatus(
    featureName: AllFeatures,
    featureUseLimits?: CommonFeatureUseLimitData[],
    type?: FeatureTypes
  ): FeatureUseStatus {
    if (!featureUseLimits) {
      return FeatureUseStatus.CANNOT_USE;
    }
    const useLimit = featureUseLimits.find(
      (item) => item.name === featureName && (!type || item.type === type)
    );

    if (useLimit === undefined) {
      return FeatureUseStatus.CANNOT_USE;
    }

    if (!useLimit.can_use) {
      return FeatureUseStatus.CANNOT_USE;
    }

    if (
      useLimit.is_metered &&
      useLimit.times_triggered_in_cycle >= useLimit.can_use_times
    ) {
      return FeatureUseStatus.LIMIT_REACHED;
    }

    const expirationDate = dayjs(useLimit.specific_rule_expire_at);
    const now = dayjs();

    if (expirationDate.isBefore(now)) {
      return FeatureUseStatus.EXPIRED;
    }

    return FeatureUseStatus.CAN_USE;
  }
}
