/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import { max } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter, useHistory } from "react-router-dom";

import CustomDashboardSection from "../../components/DashboardComponents/CustomDashboardSection";
import { FacilityOverview } from "../../components/DashboardComponents/FacilityOverview";
import { FeaturesSection } from "../../components/DashboardComponents/FeaturesSection";
import { ImportantMetrics } from "../../components/DashboardComponents/ImportantMetrics";
import { MetricLineChart } from "../../components/DashboardComponents/MetricLineChart";
import { MetricPieChart } from "../../components/DashboardComponents/MetricPieChart";
import { MetricRankingList } from "../../components/DashboardComponents/MetricRankingList";
import ModalCustomDashboardConditions from "../../components/DashboardComponents/ModalCreateEditCustomDashboards";
import { RecentMediaList } from "../../components/DashboardComponents/RecentMediaList";
import RecentPostList, {
  RecentMediaListContext,
} from "../../components/DashboardComponents/RecentPostsList";
import { Suggestions } from "../../components/DashboardComponents/Suggestions";
import { Summary } from "../../components/FacilityDetailsComponents/Summary";
import { ModalProfileCompletionLeaderboard } from "../../components/ModalProfileCompletionLeaderboard";
import { ScoreItem } from "../../components/ScoreItem";
import { TipsIcon } from "../../components/TipsIcon";
import { useUpdateImpersonatedOrganizationContext } from "../../hooks/organizationImpersonation";
import { useMe } from "../../hooks/queries";
import { canAccessOrganization } from "../../hooks/validateUserOrganization";
import { AccountType } from "../../models/AccountData";
import { CustomDashboardParams } from "../../models/CustomDashboardParams";
import { FacilityFilterConditionData } from "../../models/FacilityFilterConditionData";
import { FeatureTypes } from "../../models/featureUseLimits/FeatureUseLimitsData.const";
import { LocalPostData } from "../../models/LocalPostData";
import { MediaData } from "../../models/MediaData";
import {
  OrgDailyMetricsData,
  OrgDailyMetricsType,
} from "../../models/MetricsLineChartData";
import "./styles.scss";
import { MetricsPieChartFromAPIData } from "../../models/MetricsPieChartData";
import DataSvc from "../../services/dataSvc";
import { percentage } from "../../utils/common";
import { convertFormDataToCustomDashboardParams } from "../../utils/conditionFormDataHelpers";
import { getScoreType } from "../../utils/score";

type IDashboardPageProps = RouteComponentProps<any>;

const DashboardPage: React.FunctionComponent<IDashboardPageProps> = (props) => {
  const DATA_RANGE_LENGTH_DAYS = 29;
  const DATA_RANGE_END_DATE_DAYS_AGO = 5;
  const DATA_RANGE_START_DATE_DAYS_AGO =
    DATA_RANGE_END_DATE_DAYS_AGO + DATA_RANGE_LENGTH_DAYS; // 28+6=34
  const PREV_DATA_RANGE_END_DATE_DAYS_AGO = DATA_RANGE_START_DATE_DAYS_AGO; // 34
  const PREV_DATA_RANGE_START_DATE_DAYS_AGO =
    PREV_DATA_RANGE_END_DATE_DAYS_AGO + DATA_RANGE_LENGTH_DAYS; // 34+28+1=63

  const SUGGESTION_SIZE = 2;

  const METRICS_START_DATE = moment()
    .subtract(DATA_RANGE_START_DATE_DAYS_AGO, "days")
    .add(9, "hours")
    .hour(0)
    .minute(0)
    .second(0)
    .toDate();
  const METRICS_END_DATE = moment()
    .subtract(DATA_RANGE_END_DATE_DAYS_AGO, "days")
    .add(9, "hours")
    .hour(0)
    .minute(0)
    .second(0)
    .toDate();
  const PREV_METRICS_START_DATE = moment()
    .subtract(PREV_DATA_RANGE_START_DATE_DAYS_AGO, "days")
    .add(9, "hours")
    .hour(0)
    .minute(0)
    .second(0)
    .toDate();
  const PREV_METRICS_END_DATE = moment()
    .subtract(PREV_DATA_RANGE_END_DATE_DAYS_AGO, "days")
    .add(9, "hours")
    .hour(0)
    .minute(0)
    .second(0)
    .toDate();

  const nextSuggestionUpdateDate = moment()
    .clone()
    .add(1, "week")
    .startOf("week")
    .add(1, "day")
    .format("YYYY-MM-DD");

  const { t: _t } = useTranslation();
  const t = (key: string, params = {}) =>
    _t(`dashboardPage.${key}`, { ...params });

  const [
    showProfileCompletionLeaderboardModal,
    setShowProfileCompletionLeaderboardModal,
  ] = useState(false);

  const [organizationId, setOrganizationId] = useState<string>("");
  const [organizationName, setOrganizationName] = useState<string>("");

  const [activeDashboard, setActiveDashboard] = useState<
    CustomDashboardParams | undefined
  >(undefined);
  const [customDashboards, setCustomDashboards] = useState<
    CustomDashboardParams[] | undefined
  >(undefined);
  const [showModalCustomDashboard, setShowModalCustomDashboard] =
    useState(false);
  const [modalCustomDashboardIsCreate, setModalCustomDashboardIsCreate] =
    useState(false);
  const [activeDashboardVersion, setactiveDashboardVersion] = useState<
    string | undefined
  >(undefined);
  const [recentMediaList, setRecentMediaList] = useState<MediaData[]>([]);
  const [recentLocalPostList, setRecentLocalPostList] = useState<
    LocalPostData[]
  >([]);

  const history = useHistory();

  const { data: mediaList } = useQuery(
    ["dashboard.media", organizationId],
    () => {
      if (organizationId) {
        return DataSvc.getMedia({
          limit: 10,
          offset: 0,
          order: "desc",
          sort: "post_date",
          onePerLocation: true,
          organization_id: [organizationId],
        });
      }
      return null;
    }
  );

  const { data: localPostList } = useQuery(
    ["dashboard.localPosts", organizationId],
    () => {
      if (organizationId) {
        return DataSvc.getLocalPosts({
          limit: 10,
          offset: 0,
          order: "desc",
          sort: "post_date",
          onePerLocation: true,
          organization_id: [organizationId],
        });
      }
      return null;
    }
  );

  const { data: statsPrime } = useQuery(["statsPrime", organizationId], () => {
    if (organizationId) {
      return DataSvc.getStatsPrime(organizationId);
    }
    return null;
  });

  const { data: statsStatus } = useQuery(
    ["statsStatus", organizationId, activeDashboardVersion],
    () => {
      if (organizationId) {
        return DataSvc.getStatsStatus(organizationId, activeDashboard);
      }
      return null;
    }
  );

  // get the latest recommendation history
  const { data: latestRecommendationHistory } = useQuery(
    ["latest-recommendation-history", organizationId],
    async () => {
      if (organizationId) {
        return await DataSvc.getRecommendationsHistory(organizationId, 1, true);
      }
      return null;
    }
  );

  // get altest recommendations generated for the organization
  const { data: orgRecommendations } = useQuery(
    ["org-recommendations", organizationId, latestRecommendationHistory],
    async () => {
      if (organizationId && latestRecommendationHistory) {
        let lastGeneratedDate;
        if (latestRecommendationHistory?.histories?.length > 0) {
          lastGeneratedDate =
            latestRecommendationHistory?.histories[0].generated_date;
        }
        if (lastGeneratedDate) {
          return await DataSvc.getRecommendations(
            {
              organization_id: organizationId,
              generated_date: lastGeneratedDate,
              main_section: true,
              limit: SUGGESTION_SIZE,
            },
            true
          );
        }
      }

      return [];
    }
  );

  // FOR CUSTOM DASHBOARDS //
  const { data: custDashboards, refetch: refetchCustomDashboards } = useQuery(
    ["customDashboards", organizationId],
    () => {
      if (organizationId) {
        return DataSvc.getCustomDashboards(organizationId);
      }

      return null;
    }
  );

  const { data: customOrgRecommendation } = useQuery(
    ["customOrgRecommendations", activeDashboard, activeDashboardVersion],
    async () => {
      let lastGeneratedDate;
      if (latestRecommendationHistory?.histories?.length > 0) {
        lastGeneratedDate =
          latestRecommendationHistory?.histories[0].generated_date;
      }

      if (activeDashboard) {
        return await DataSvc.getCustomOrgRecommendations(
          {
            organization_id: activeDashboard.organization_id,
            custom_dashboard_id: activeDashboard.id,
            generated_date: lastGeneratedDate,
            main_section: true,
            limit: SUGGESTION_SIZE,
          },
          true
        );
      }

      return [];
    }
  );

  const { data: statsCustomPrime } = useQuery(
    ["statsCustomPrime", activeDashboard, activeDashboardVersion],
    async () => {
      if (activeDashboard) {
        return await DataSvc.getStatsCustomPrime(
          String(activeDashboard.organization_id),
          String(activeDashboard.id)
        );
      }

      return null;
    }
  );

  useEffect(() => {
    if (mediaList) {
      setRecentMediaList(mediaList);
    }
  }, [mediaList]);

  useEffect(() => {
    if (localPostList) {
      setRecentLocalPostList(localPostList);
    }
  }, [localPostList]);

  useEffect(() => {
    let activeDashboardPresent = false;

    if (custDashboards) {
      setCustomDashboards(custDashboards);
      const activeDashboardId = props.match.params.customDashboardId;
      if (custDashboards && activeDashboardId) {
        const activeDashboard = custDashboards.find(
          (dashboard) => dashboard.id === activeDashboardId
        );
        setActiveDashboard(activeDashboard);

        if (activeDashboard) {
          activeDashboardPresent = true;
          setactiveDashboardVersion(
            `${activeDashboardId}-${activeDashboard.last_modified_at}`
          );
        }
      }
    }

    if (!activeDashboardPresent) {
      setactiveDashboardVersion(undefined);
    }
  }, [custDashboards]);

  const onOpenCreateEditCustomDashboard = (isCreate: boolean) => {
    setModalCustomDashboardIsCreate(isCreate);
    setShowModalCustomDashboard(true);
  };

  const onApplyCustomDashboard = (
    title: string,
    conditionFormData: FacilityFilterConditionData,
    dashboardId?: number
  ) => {
    const conditions = JSON.stringify(
      convertFormDataToCustomDashboardParams(conditionFormData)
    );
    if (dashboardId) {
      DataSvc.updateCustomDashboard(dashboardId, {
        title,
        conditions,
      }).then(() => {
        refetchCustomDashboards();
      });
    } else {
      DataSvc.createCustomDashboard({
        title,
        conditions,
        organization_id: Number(organizationId),
      }).then((newDashboard) => {
        history.push(
          `/dashboard/organization/${organizationId}/customDashboard/${newDashboard.id}`
        );
      });
    }
    setShowModalCustomDashboard(false);
  };

  const onDeleteCustomDashboard = (dashboardId: number) => {
    DataSvc.deleteCustomDashboard(dashboardId).then(() => {
      history.push(`/dashboard/organization/${organizationId}/`);
    });
  };

  // END FOR CUSTOM DASHBOARDS

  const [impressionsChartData, setImpressionsChartData] =
    useState<OrgDailyMetricsData>({
      title: "impressions",
      dataSources: [
        {
          label: t(`metricLineChart.views`),
          fieldName: "views",
          style: "solid",
          color: "#4753EF",
        },
        {
          label: t(`metricLineChart.prev_period`),
          fieldName: "prev views",
          style: "dashed",
          color: "#4753EF",
        },
      ],
      dataForLine: [],
    });

  const [userActivityChartData, setUserActivityChartData] =
    useState<OrgDailyMetricsData>({
      title: "user activity",
      dataSources: [
        {
          label: t(`metricLineChart.website_clicks`),
          fieldName: "website clicks",
          style: "solid",
          color: "#4753EF",
        },
        {
          label: t(`metricLineChart.prev_period`),
          fieldName: "prev website clicks",
          style: "dashed",
          color: "#4753EF",
        },
        {
          label: t(`metricLineChart.call_button_clicks`),
          fieldName: "call button clicks",
          style: "solid",
          color: "#101CB9",
        },
        {
          label: t(`metricLineChart.prev_period`),
          fieldName: "prev call button clicks",
          style: "dashed",
          color: "#101CB9",
        },
        {
          label: t(`metricLineChart.route_searches`),
          fieldName: "route searches",
          style: "solid",
          color: "#FFB3A2",
        },
        {
          label: t(`metricLineChart.prev_period`),
          fieldName: "prev route searches",
          style: "dashed",
          color: "#FFB3A2",
        },
      ],
      dataForLine: [],
    });

  const [bookingFoodOrdersData, setBookingFoodOrdersData] =
    useState<OrgDailyMetricsData>({
      title: "booking food orders",
      dataSources: [
        {
          label: t(`metricLineChart.booking_food_orders_count`),
          fieldName: "booking food orders",
          style: "solid",
          color: "#4753EF",
        },
        {
          label: t(`metricLineChart.prev_period`),
          fieldName: "prev booking food orders",
          style: "dashed",
          color: "#4753EF",
        },
      ],
      dataForLine: [],
    });

  // metrics section data
  const [metricsLineChartSectionData, setMetricsLineChartSectionData] =
    useState<OrgDailyMetricsData>({
      title: "impressions",
      dataSources: [
        // {
        //   label: t(`metricLineChart.schedule_updates`),
        //   fieldName: 'schedule updates',
        //   style: 'solid',
        //   color: '#3241FF',
        // },
        {
          label: t(`metricLineChart.posts`),
          fieldName: "posts",
          style: "solid",
          color: "#101CB9",
        },
        {
          label: t(`metricLineChart.media_posts`),
          fieldName: "media posts",
          style: "solid",
          color: "#9BA2FC",
        },
      ],
      dataForLine: [],
    });

  const { data: locationEventsLocal } = useQuery(
    ["metrics-location-events-local", organizationId, activeDashboardVersion],
    () => {
      if (organizationId) {
        return DataSvc.getLocationEvents({
          organization_id: organizationId,
          start_date: METRICS_START_DATE,
          end_date: METRICS_END_DATE,
          type: ["LOCAL_POST_POSTING"],
          ...(activeDashboard ? { ...activeDashboard.conditions } : {}),
        });
      }
      return null;
    }
  );

  const { data: locationEventsMedia } = useQuery(
    ["metrics-location-events-media", organizationId, activeDashboardVersion],
    () => {
      if (organizationId) {
        return DataSvc.getLocationEvents({
          organization_id: organizationId,
          start_date: METRICS_START_DATE,
          end_date: METRICS_END_DATE,
          type: ["MEDIA_ITEM_POSTING"],
          ...(activeDashboard ? { ...activeDashboard.conditions } : {}),
        });
      }
      return null;
    }
  );

  useEffect(() => {
    if (locationEventsLocal && locationEventsMedia) {
      const dataForLine = [];
      const dataLength =
        max([
          locationEventsLocal.metrics.length,
          locationEventsMedia.metrics.length,
        ]) || 0;
      for (let i = 0; i < dataLength; ++i) {
        dataForLine.push({
          label: locationEventsLocal.metrics[i]?.date,
          posts: locationEventsLocal.metrics[i]?.value,
          "media posts": locationEventsMedia.metrics[i]?.value,
        });
      }
      setMetricsLineChartSectionData({
        ...metricsLineChartSectionData,
        dataForLine,
      });
    }
  }, [locationEventsLocal, locationEventsMedia]);

  const getMetrics = (type: OrgDailyMetricsType[]) => {
    if (organizationId) {
      return DataSvc.getMetrics({
        organization_id: organizationId,
        start_date: METRICS_START_DATE,
        end_date: METRICS_END_DATE,
        type,
        ...(activeDashboard ? { ...activeDashboard.conditions } : {}),
      });
    }
    return null;
  };

  const getPrevMetrics = (type: OrgDailyMetricsType[]) => {
    if (organizationId) {
      return DataSvc.getMetrics({
        organization_id: organizationId,
        start_date: PREV_METRICS_START_DATE,
        end_date: PREV_METRICS_END_DATE,
        type,
        ...(activeDashboard ? { ...activeDashboard.conditions } : {}),
      });
    }
    return null;
  };

  const { data: metricsViewsMaps } = useQuery(
    ["metrics-views-maps", organizationId, activeDashboardVersion],
    () =>
      getMetrics([
        "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
        "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH",
        "BUSINESS_IMPRESSIONS_MOBILE_MAPS",
        "BUSINESS_IMPRESSIONS_MOBILE_SEARCH",
      ])
  );

  const { data: metricsPrevViewsMaps } = useQuery(
    ["metrics-prev-views-maps", organizationId, activeDashboardVersion],
    () =>
      getPrevMetrics([
        "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
        "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH",
        "BUSINESS_IMPRESSIONS_MOBILE_MAPS",
        "BUSINESS_IMPRESSIONS_MOBILE_SEARCH",
      ])
  );

  const { data: me } = useMe(true);

  const updateImpersonatedOrganizationContext =
    useUpdateImpersonatedOrganizationContext();

  useEffect(() => {
    if (!me) {
      return;
    }

    const matchOrganizationId = props.match.params.organizationId;

    if (me.type === AccountType.LOC_ADMIN) {
      if (!!me.linked_to_google_account) {
        history.push(`/locations/`);
      } else {
        history.push(`/management/locations`);
      }
    } else {
      if (canAccessOrganization(matchOrganizationId, me)) {
        setOrganizationName(me.organization.name);
        setOrganizationId(matchOrganizationId);
        updateImpersonatedOrganizationContext(matchOrganizationId);
      } else if (me.organization?.id) {
        history.push(`/dashboard/organization/${me.organization?.id || ""}`);
      }
    }

    return () => {
      updateImpersonatedOrganizationContext(null);
    };
  }, [props.match.params.organizationId, me]);

  useEffect(() => {
    if (metricsViewsMaps && metricsPrevViewsMaps) {
      const dataForLine = [];
      const dataLength =
        max([
          metricsViewsMaps.metrics.length,
          metricsPrevViewsMaps.metrics.length,
        ]) || 0;
      for (let i = 0; i < dataLength; ++i) {
        dataForLine.push({
          label: metricsViewsMaps.metrics[i]?.date,
          views: metricsViewsMaps.metrics[i]?.value,
          "prev views": metricsPrevViewsMaps.metrics[i]?.value,
        });
      }
      setImpressionsChartData({
        ...impressionsChartData,
        dataForLine,
      });
    }
  }, [metricsViewsMaps, metricsPrevViewsMaps]);

  const { data: metricsActionsWebsite } = useQuery(
    ["metrics-actions-website", organizationId, activeDashboardVersion],
    () => getMetrics(["WEBSITE_CLICKS"])
  );

  const { data: metricsPrevActionsWebsite } = useQuery(
    ["metrics-prev-actions-website", organizationId, activeDashboardVersion],
    () => getPrevMetrics(["WEBSITE_CLICKS"])
  );

  const { data: metricsActionsPhone } = useQuery(
    ["metrics-actions-phone", organizationId, activeDashboardVersion],
    () => getMetrics(["CALL_CLICKS"])
  );

  const { data: metricsPrevActionsPhone } = useQuery(
    ["metrics-prev-actions-phone", organizationId, activeDashboardVersion],
    () => getPrevMetrics(["CALL_CLICKS"])
  );

  const { data: metricsActionsDrivingDirections } = useQuery(
    [
      "metrics-actions-driving-directions",
      organizationId,
      activeDashboardVersion,
    ],
    () => getMetrics(["BUSINESS_DIRECTION_REQUESTS"])
  );

  const { data: metricsPrevActionsDrivingDirections } = useQuery(
    [
      "metrics-prev-actions-driving-directions",
      organizationId,
      activeDashboardVersion,
    ],
    () => getPrevMetrics(["BUSINESS_DIRECTION_REQUESTS"])
  );

  useEffect(() => {
    if (
      metricsActionsWebsite &&
      metricsPrevActionsWebsite &&
      metricsActionsPhone &&
      metricsPrevActionsPhone &&
      metricsActionsDrivingDirections &&
      metricsPrevActionsDrivingDirections
    ) {
      const dataForLine = [];
      const dataLength =
        max([
          metricsActionsWebsite.metrics.length,
          metricsPrevActionsWebsite.metrics.length,
          metricsActionsPhone.metrics.length,
          metricsPrevActionsPhone.metrics.length,
          metricsActionsDrivingDirections.metrics.length,
          metricsPrevActionsDrivingDirections.metrics.length,
        ]) || 0;
      for (let i = 0; i < dataLength; ++i) {
        dataForLine.push({
          label: metricsActionsWebsite.metrics[i]?.date,
          "website clicks": metricsActionsWebsite.metrics[i]?.value,
          "prev website clicks": metricsPrevActionsWebsite.metrics[i]?.value,
          "call button clicks": metricsActionsPhone.metrics[i]?.value,
          "prev call button clicks": metricsPrevActionsPhone.metrics[i]?.value,
          "route searches": metricsActionsDrivingDirections.metrics[i]?.value,
          "prev route searches":
            metricsPrevActionsDrivingDirections.metrics[i]?.value,
        });
      }
      setUserActivityChartData({
        ...userActivityChartData,
        dataForLine,
      });
    }
  }, [
    metricsActionsWebsite,
    metricsPrevActionsWebsite,
    metricsActionsPhone,
    metricsPrevActionsPhone,
    metricsActionsDrivingDirections,
    metricsPrevActionsDrivingDirections,
  ]);

  const { data: metricsBookingFoodOrders } = useQuery(
    ["metrics-booking-food-orders", organizationId, activeDashboardVersion],
    () => getMetrics(["BUSINESS_BOOKINGS", "BUSINESS_FOOD_ORDERS"])
  );

  const { data: metricsPrevBookingFoodOrders } = useQuery(
    [
      "metrics-prev-booking-food-orders",
      organizationId,
      activeDashboardVersion,
    ],
    () => getPrevMetrics(["BUSINESS_BOOKINGS", "BUSINESS_FOOD_ORDERS"])
  );

  useEffect(() => {
    if (metricsBookingFoodOrders && metricsPrevBookingFoodOrders) {
      const dataForLine = [];
      const dataLength =
        max([
          metricsBookingFoodOrders.metrics.length,
          metricsPrevBookingFoodOrders.metrics.length,
        ]) || 0;
      for (let i = 0; i < dataLength; ++i) {
        dataForLine.push({
          label: metricsBookingFoodOrders.metrics[i]?.date,
          "booking food orders": metricsBookingFoodOrders.metrics[i]?.value,
          "prev booking food orders":
            metricsPrevBookingFoodOrders.metrics[i]?.value,
        });
      }
      setBookingFoodOrdersData({
        ...bookingFoodOrdersData,
        dataForLine,
      });
    }
  }, [metricsBookingFoodOrders, metricsPrevBookingFoodOrders]);

  const [metricsPieChart, setMetricsPieChart] =
    useState<MetricsPieChartFromAPIData[]>();

  const { data: metricsViewsSearchMobile } = useQuery(
    ["metrics-views-search-mobile", organizationId, activeDashboardVersion],
    () => getMetrics(["BUSINESS_IMPRESSIONS_MOBILE_SEARCH"])
  );

  const { data: metricsViewsMapsMobile } = useQuery(
    ["metrics-views-maps-mobile", organizationId, activeDashboardVersion],
    () => getMetrics(["BUSINESS_IMPRESSIONS_MOBILE_MAPS"])
  );

  const { data: metricsViewsSearchDesktop } = useQuery(
    ["metrics-views-search-desktop", organizationId, activeDashboardVersion],
    () => getMetrics(["BUSINESS_IMPRESSIONS_DESKTOP_SEARCH"])
  );

  const { data: metricsViewsMapsDesktop } = useQuery(
    ["metrics-views-maps-desktop", organizationId, activeDashboardVersion],
    () => getMetrics(["BUSINESS_IMPRESSIONS_DESKTOP_MAPS"])
  );

  useEffect(() => {
    if (
      metricsViewsSearchMobile &&
      metricsViewsMapsMobile &&
      metricsViewsSearchDesktop &&
      metricsViewsMapsDesktop
    ) {
      const data = [];
      data.push({
        label: t(`metricPieChart.search_mobile`),
        unit: t(`metricPieChart.unit_view`),
        color: "#1036B9",
        data: metricsViewsSearchMobile.metrics.reduce(
          (prev: number, cur: any) => {
            return prev + parseInt(cur.value);
          },
          0
        ),
      });
      data.push({
        label: t(`metricPieChart.maps_mobile`),
        unit: t(`metricPieChart.unit_view`),
        color: "#4753EF",
        data: metricsViewsMapsMobile.metrics.reduce(
          (prev: number, cur: any) => {
            return prev + parseInt(cur.value);
          },
          0
        ),
      });
      data.push({
        label: t(`metricPieChart.search_desktop`),
        unit: t(`metricPieChart.unit_view`),
        color: "#9BA2FC",
        data: metricsViewsSearchDesktop.metrics.reduce(
          (prev: number, cur: any) => {
            return prev + parseInt(cur.value);
          },
          0
        ),
      });
      data.push({
        label: t(`metricPieChart.maps_desktop`),
        unit: t(`metricPieChart.unit_view`),
        color: "#FFB3A2",
        data: metricsViewsMapsDesktop.metrics.reduce(
          (prev: number, cur: any) => {
            return prev + parseInt(cur.value);
          },
          0
        ),
      });
      setMetricsPieChart(data);
    }
  }, [
    metricsViewsSearchMobile,
    metricsViewsMapsMobile,
    metricsViewsMapsDesktop,
    metricsViewsSearchDesktop,
  ]);

  // RANKING RELATED

  const getRanking = (path: string, metric: string) => {
    if (organizationId) {
      return DataSvc.getRanking(path, {
        organization_id: organizationId,
        metric,
        start_date: METRICS_START_DATE,
        end_date: METRICS_END_DATE,
        size: 20,
        ...(activeDashboard ? { ...activeDashboard.conditions } : {}),
      });
    }
    return null;
  };

  const getPrevRanking = (path: string, metric: string) => {
    if (organizationId) {
      return DataSvc.getRanking(path, {
        organization_id: organizationId,
        metric,
        start_date: PREV_METRICS_START_DATE,
        end_date: PREV_METRICS_END_DATE,
        size: 20,
        ...(activeDashboard ? { ...activeDashboard.conditions } : {}),
      });
    }
    return null;
  };

  const { data: metricsRankingListLocalities } = useQuery(
    ["metrics-ranking-localities", organizationId, activeDashboardVersion],
    () => getRanking("municipalities", "page_view")
  );

  const { data: metricsRankingListLocalitiesPrev } = useQuery(
    ["metrics-prev-ranking-localities", organizationId, activeDashboardVersion],
    () => getPrevRanking("municipalities", "page_view")
  );

  const { data: metricsRankingListFacilities } = useQuery(
    ["metrics-ranking-facilities", organizationId, activeDashboardVersion],
    () => getRanking("locations", "page_view")
  );

  const { data: metricsRankingListFacilitiesPrev } = useQuery(
    ["metrics-prev-ranking-facilities", organizationId, activeDashboardVersion],
    () => getPrevRanking("locations", "page_view")
  );

  const { data: metricsRankingListFacilityCategory } = useQuery(
    [
      "metrics-ranking-facility-category",
      organizationId,
      activeDashboardVersion,
    ],
    () => getRanking("categories", "page_view")
  );

  const { data: metricsRankingListFacilityCategoryPrev } = useQuery(
    [
      "metrics-prev-ranking-facility-category",
      organizationId,
      activeDashboardVersion,
    ],
    () => getPrevRanking("categories", "page_view")
  );

  const { data: metricsRankingListLocations } = useQuery(
    ["metrics-ranking-locations", organizationId, activeDashboardVersion],
    () => getRanking("locations", "review_count")
  );

  const { data: metricsRankingListLocationsPrev } = useQuery(
    ["metrics-prev-ranking-locations", organizationId, activeDashboardVersion],
    () => getPrevRanking("locations", "review_count")
  );

  // END RANKING RELATED

  const getPeriodText = () => {
    const fromDate = METRICS_START_DATE;
    const toDate = moment(METRICS_END_DATE).subtract(1, "days").toDate();
    return _t("dashboardPage.period", {
      fromDate: fromDate.getMonth() + 1 + "/" + fromDate.getDate(),
      toDate: toDate.getMonth() + 1 + "/" + toDate.getDate(),
    });
  };

  // get Profile Views Change ratio
  const getProfileViewsChangeRatio = (): number => {
    const targetPrime = activeDashboard ? statsCustomPrime : statsPrime;
    if (!targetPrime?.stats) {
      return 0;
    }
    return (
      ((targetPrime.stats.profile_views -
        targetPrime.stats.prev_profile_views) /
        targetPrime.stats.prev_profile_views) *
      100
    );
  };

  const getFirstSentenceValue = () => {
    const targetPrime = activeDashboard ? statsCustomPrime : statsPrime;
    return targetPrime?.stats?.score ?? 0;
  };

  const onOpenProfileCompletionLeaderboardModal = () => {
    setShowProfileCompletionLeaderboardModal(true);
  };

  return (
    <>
      <div className="right-content dashboard">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("dashboard")}</div>
          {!activeDashboard && (
            <div className="rights flex">
              <div className="flex">
                <TipsIcon
                  tipsLabel={t("profile_completion_explanation")}
                  position={"bottom"}
                  scale={2}
                />
                <div className="profile-completion-level">
                  {t("profile_completion_level")}
                </div>
                <div className="">
                  {!!statsPrime?.stats && (
                    <ScoreItem score={getScoreType(statsPrime.stats.score)} />
                  )}
                </div>
              </div>
              <TipsIcon
                tipsLabel={t("ranking_explanation")}
                position={"bottom"}
                scale={2}
              />
              <button
                onClick={onOpenProfileCompletionLeaderboardModal}
                className="btn btn-border"
              >
                {t("see_leaderboard")}
              </button>
            </div>
          )}
        </div>
        <div className="top-section-group">
          {customDashboards && (
            <CustomDashboardSection
              customDashboards={customDashboards}
              activeDashboard={activeDashboard}
              organizationId={organizationId}
              onOpenCreateEditCustomDashboard={onOpenCreateEditCustomDashboard}
            />
          )}
          {organizationId && (
            <FeaturesSection type={FeatureTypes.ORG} id={organizationId} />
          )}
        </div>
        <div className="dashboard-group">
          <div className="group-title">
            {t("report_card")}
            <div className="line"></div>
          </div>
          {activeDashboard && !activeDashboard.has_calculated_stats ? (
            <div className="hints">
              {t("summary_had_not_been_calculated_yet")}
            </div>
          ) : (
            <>
              <ImportantMetrics
                dataList={activeDashboard ? statsCustomPrime : statsPrime}
              />
              <Summary
                statsPrime={activeDashboard ? statsCustomPrime : statsPrime}
                firstSentenceValue={getFirstSentenceValue()}
                secondSentenceValue={
                  statsPrime || statsCustomPrime
                    ? percentage(getProfileViewsChangeRatio())
                    : 0
                }
                pageType="OrganizationDashboard"
              />
            </>
          )}
          {activeDashboard &&
          !activeDashboard.has_calculated_recommendations ? (
            <div className="hints">
              {t("suggestions_had_not_been_calculated_yet", {
                date: nextSuggestionUpdateDate,
              })}
            </div>
          ) : (
            <Suggestions
              buttonType={"button"}
              shownAll={false}
              minimumSize={SUGGESTION_SIZE}
              isDashboard={true}
              organization_id={organizationId}
              recommendations={
                activeDashboard ? customOrgRecommendation : orgRecommendations
              }
            />
          )}
        </div>
        <div className="dashboard-group">
          <div className="group-title">
            {t("facility_overview")}
            <div className="line"></div>
          </div>
          <FacilityOverview
            organization_id={organizationId}
            dataList={statsStatus}
          />
        </div>

        <div className="dashboard-group">
          <div className="group-title">
            {t("recent_posts")}
            <div className="line"></div>
          </div>
          <small className="status-hint">
            {t("differs_from_actual_status")}
          </small>
          <RecentPostList
            dataList={recentLocalPostList}
            context={RecentMediaListContext.FACILITY_UNDER_ORG}
          />
        </div>

        <div className="dashboard-group">
          <div className="group-title">
            {t("recent_media")}
            <div className="line"></div>
          </div>
          <small className="status-hint">
            {t("differs_from_actual_status")}
          </small>
          <RecentMediaList dataList={recentMediaList} />
        </div>
        <div className="dashboard-group">
          <div className="group-title">
            {t("metrics_by_category")}
            {getPeriodText()}
            <div className="line"></div>
          </div>

          <div className="two-panel">
            <div className="row">
              <div className="col col-md-6">
                <div className="panel-wrap">
                  <MetricLineChart
                    title={"impressions"}
                    dataList={impressionsChartData}
                  />
                </div>
                <div className="panel-wrap">
                  <MetricLineChart
                    title={"user_activity"}
                    dataList={userActivityChartData}
                  />
                </div>
                <div className="panel-wrap">
                  <MetricRankingList
                    title={"top_20_facilities_by_views"}
                    link="location"
                    dataList={metricsRankingListFacilities}
                    dataListPrev={metricsRankingListFacilitiesPrev}
                  />
                </div>
                <div className="panel-wrap">
                  <MetricRankingList
                    title={"top_20_facility_category_by_views"}
                    link="category"
                    isShownNumberOfFacilities={true}
                    dataList={metricsRankingListFacilityCategory}
                    dataListPrev={metricsRankingListFacilityCategoryPrev}
                  />
                </div>
              </div>
              <div className="col col-md-6">
                <div className="panel-wrap">
                  <MetricLineChart
                    title={"booking_food_orders"}
                    dataList={bookingFoodOrdersData}
                  />
                </div>
                <div className="panel-wrap">
                  <MetricLineChart
                    title={"total_facility_activity"}
                    dataList={metricsLineChartSectionData}
                  />
                </div>
                <div className="panel-wrap">
                  <MetricRankingList
                    link="municipality"
                    title={"top_20_localities_by_views"}
                    isShownNumberOfFacilities={true}
                    dataList={metricsRankingListLocalities}
                    dataListPrev={metricsRankingListLocalitiesPrev}
                  />
                </div>
                <div className="panel-wrap">
                  <MetricRankingList
                    link="location"
                    title={"top_20_locations_by_reviews"}
                    dataList={metricsRankingListLocations}
                    dataListPrev={metricsRankingListLocationsPrev}
                  />
                </div>
                <div className="panel-wrap">
                  <MetricPieChart
                    title={"view_location"}
                    dataList={metricsPieChart}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showProfileCompletionLeaderboardModal && (
        <ModalProfileCompletionLeaderboard
          organizationName={organizationName}
          organizationId={organizationId}
          onClose={() => {
            setShowProfileCompletionLeaderboardModal(false);
          }}
        />
      )}

      {showModalCustomDashboard && (
        <ModalCustomDashboardConditions
          organizationId={organizationId}
          customDashboards={customDashboards}
          dashboardToEdit={
            !modalCustomDashboardIsCreate && activeDashboard
              ? activeDashboard
              : undefined
          }
          onClose={() => {
            setShowModalCustomDashboard(false);
          }}
          onApply={(
            title: string,
            conditionFormData: FacilityFilterConditionData
          ): void => {
            onApplyCustomDashboard(
              title,
              conditionFormData,
              modalCustomDashboardIsCreate ? undefined : activeDashboard?.id
            );
          }}
          onDelete={(dashboardId: number) => {
            onDeleteCustomDashboard(dashboardId);
          }}
        />
      )}
    </>
  );
};

export default withRouter(DashboardPage);
