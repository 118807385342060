import React from "react";
import { useTranslation } from "react-i18next";

export interface IHookFormSubmitButtonProps {
  className?: string;
  label?: string;
}

export const HookFormSubmitButton = ({
  className,
  label,
}: IHookFormSubmitButtonProps) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`hookForm.submitButton.${key}`);

  return (
    <button className={`btn btn-blue ${className}`} type="submit">
      {label || t("submit")}
    </button>
  );
};
