import React, { useContext, useState } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";

import { ConfirmCloseContext } from "../../../../contexts/confirmClose";
import ModalConfirm from "../../../ModalConfirm";

import "./styles.scss";

export interface IAccordionButtonProps {
  children: React.ReactNode;
  accordionItemKey: string;
}

/**
 * This button acts as a label for an AccordionItem which opens and closes the section, while also checking the ConfirmCloseContext to make sure to prompt the user when attempting to close a section that does not want to be closed accidentally.
 */
export const AccordionButton: React.FunctionComponent<
  IAccordionButtonProps
> = ({ children, accordionItemKey }) => {
  const toggleAccordion = useAccordionButton(accordionItemKey);

  const { getRegistrations, closeAll } = useContext(ConfirmCloseContext);
  const { activeEventKey } = useContext(AccordionContext);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onClick = (event: React.MouseEvent) => {
    const closeStatus = getRegistrations();
    if (closeStatus.length > 0) {
      setShowConfirmationModal(true);
    } else {
      toggleAccordion(event);
    }
  };

  const handleClose = (event: React.MouseEvent) => {
    setShowConfirmationModal(false);
    closeAll();
    toggleAccordion(event);
  };

  return (
    <>
      <button
        className={`accordion-button-label ${
          activeEventKey === accordionItemKey ? "active" : ""
        }`}
        onClick={onClick}
        type="button"
      >
        {children}
      </button>
      {showConfirmationModal && (
        <ModalConfirm
          title={"close_dirty"}
          onClose={() => setShowConfirmationModal(false)}
          cancelLabel={"cancel"}
          confirmLabel={"discard"}
          onConfirm={handleClose}
        />
      )}
    </>
  );
};
