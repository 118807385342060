import React from "react";
import { useTranslation } from "react-i18next";

import { IAnalyseAddFormData } from "../CreateNewAnalysisModal";
import "./styles.scss";

export interface IModalNewAnalysisConfirmProps {
  onClose: () => void;
  onSave: () => void;
  formData: IAnalyseAddFormData;
}

export const ModalNewReviewAnalysisConfirm: React.FunctionComponent<
  IModalNewAnalysisConfirmProps
> = (props) => {
  const { onClose, onSave, formData } = props;
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`customizedReviewsAnalysisPage.modalNewReviewAnalysisConfirm.${key}`);

  return (
    <div className="modal modal-default modal-new-analysis-confirm">
      <div className="modal-mains">
        <div
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        ></div>
        <div className="modal-mains__main">
          <div className="top-title flex-grid">{t("new_analysis")}</div>
          <hr />

          <div className="top-title">{t("locations")}</div>
          {formData.locations?.map((location, index) => (
            <div className="top-sub-title " key={index}>
              {`・${location?.name}`}
            </div>
          ))}

          <div className="top-title">{t("locations")}</div>
          {formData.languages?.map((language, index) => (
            <div className="top-sub-title " key={index}>
              {`・${language.title}`}
            </div>
          ))}
          <br />

          <div className="top-title">{t("period")}</div>
          <div className="top-sub-title">{`${formData.start_date} - ${formData.end_date}`}</div>

          <div className="bottom-btns">
            <div
              className="btn btn-border"
              onClick={() => {
                onClose();
              }}
            >
              {t("cancel")}
            </div>
            <div
              className={`btn btn-blue `}
              onClick={() => {
                onSave();
              }}
            >
              {t("start")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalNewReviewAnalysisConfirm;
