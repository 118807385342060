import React from "react";

import { HookFormOperationHoursDaily } from "./Daily";
import { DaysOfWeek } from "../../../models/DayOfWeek";

import "./styles.scss";

export const HookFormOperationHours = () => {
  return (
    <div className="hook-form-operation-hours">
      {DaysOfWeek.map((day) => (
        <HookFormOperationHoursDaily key={day} day={day} />
      ))}
    </div>
  );
};
