/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery, useMutation } from "@tanstack/react-query";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

// eslint-disable-next-line import-x/no-named-as-default
import CollectionChartArea from "../../components/AnalysisCollectionsComponents/CollectionChartArea";
import { CollectionsFilterBar } from "../../components/AnalysisCollectionsComponents/CollectionsFilterBar";
import { ModalCreateEditChart } from "../../components/AnalysisCollectionsComponents/ModalCreateEditChart";
import { ModalCreateSaveCollection } from "../../components/AnalysisCollectionsComponents/ModalCreateSaveCollection";
import { useValidateOrganization } from "../../hooks/validateUserOrganization";
import {
  ChartCollectionData,
  ChartData,
} from "../../models/ChartCollectionData";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

type IAnalysisCollectionsPageProps = RouteComponentProps<any>;

const AnalysisCollectionsPage: React.FunctionComponent<
  IAnalysisCollectionsPageProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`analysisCollectionsPage.${key}`);

  const [shownModalCreateSaveCollection, setShownModalCreateSaveCollection] =
    useState<string>(""); // 'create' | 'save'
  const [shownModalCreateEditChart, setShownModalCreateEditChart] =
    useState<string>(""); // 'create' | 'edit'

  const [chartCollectionsData, setChartCollectionsData] =
    useState<ChartCollectionData>({
      name: "",
      organization_id: 0,
      is_template: true,
      id: 0,
      created_at: "",
      created_by: 0,
      last_modified_at: "",
      last_modified_by: 0,
    });
  const [chartsUpdate, setChartsUpdate] = useState<any>({});
  const [
    chartCollectionsByCollectionIdData,
    setChartCollectionsByCollectionIdData,
  ] = useState<ChartData[]>([]);
  const [editingChartData, setEditingChartData] = useState<ChartData>({
    name: "",
    collection_id: 0,
    template_id: 0,
    template_name: "",
    period_start: "",
    period_end: "",
    period_option: "custom",
    type: "bar",
    compare_to_prev: false,
    time_unit: "day",
    metrics: "",
    aggregation: "sum",
    grouping1: "",
    grouping2: "",
    prefecture_filter: "",
    municipality_filter: "",
    category_filter: "",
    label_filter: "",
    display_order: 0,
    is_template: true,
    id: 0,
    created_at: "",
    created_by: 1,
    last_modified_at: "",
    last_modified_by: 1,
  });

  const [organizationId, setOrganizationId] = useState<string>();

  useValidateOrganization({
    matchOrganizationId: props.match.params.organizationId,
    setOrganizationId,
    redirectUrlFormat: (orgId) => `/analysis/organization/${orgId}/collections`,
  });

  const createCollectionMutation = useMutation((payload: any) => {
    return DataSvc.createCollection(payload);
  });

  const { data: chartCollections } = useQuery(
    ["chartCollections", organizationId],
    () => {
      if (organizationId) {
        return DataSvc.getChartCollections({
          sort: "last_modified_at",
          order: "desc",
          limit: 1,
          organization_id: organizationId,
        }).then((collections) => {
          if (collections.length) {
            return collections;
          } else {
            return createCollectionMutation
              .mutateAsync({
                name: `Graphset ${organizationId}`,
                organization_id: parseInt(organizationId),
              })
              .then((collection) => [collection]);
          }
        });
      } else {
        return [];
      }
    }
  );

  const {
    data: chartCollectionsByCollectionId,
    refetch: getChartCollectionsByCollectionId,
  } = useQuery(["chartCollectionsByCollectionId", chartCollectionsData], () => {
    if (chartCollectionsData?.id > 0) {
      return DataSvc.getChartCollectionsByCollectionId(
        chartCollectionsData.id.toString()
      );
    } else {
      return [];
    }
  });

  useEffect(() => {
    if (chartCollections && chartCollections.length > 0) {
      setChartCollectionsData(chartCollections[chartCollections.length - 1]);
    }
    // eslint-disable-next-line
  }, [chartCollections]);

  useEffect(() => {
    if (chartCollectionsByCollectionId) {
      setChartCollectionsByCollectionIdData(
        _.cloneDeep(chartCollectionsByCollectionId)
      );
      setChartsUpdate(
        chartCollectionsByCollectionId.reduce((result, value) => {
          result[value.id] = {};
          return result;
        }, {} as any)
      );
    }
    // eslint-disable-next-line
  }, [chartCollectionsByCollectionId]);

  // on Click Save Collection
  const onClickSaveCollection = () => {
    setShownModalCreateSaveCollection("save");
  };

  const updateCollectionMutation = useMutation((payload: any) => {
    return DataSvc.updateCollection(
      payload.collectionId,
      _.omit(payload, "collectionId")
    );
  });

  const { mutate: updateChartMutation, mutateAsync: updateChartMutationAsync } =
    useMutation((payload: any) => {
      return DataSvc.updateChart(
        payload.collectionId,
        payload.chartId,
        _.omit(payload, "collectionId", "chartId")
      );
    });

  const changedCharts = useMemo(() => {
    return _.pickBy(chartsUpdate, (update, id) => {
      return (
        !_.isEmpty(update) &&
        chartCollectionsByCollectionId &&
        !_.isEqual(
          update,
          chartCollectionsByCollectionId.find((c) => c.id === parseInt(id))
        )
      );
    });
  }, [chartsUpdate, chartCollectionsByCollectionId]);

  useEffect(() => {
    if (_.isEmpty(changedCharts)) return;

    Promise.all(
      Object.entries(changedCharts).map(([id, update]) => {
        const chart = chartCollectionsByCollectionId?.find(
          (c) => c.id === parseInt(id)
        );
        if (chart) {
          return updateChartMutationAsync({
            ...update,
            collectionId: chart.collection_id,
            chartId: chart.id,
          });
        }
        return Promise.resolve();
      })
    ).then(() => {
      getChartCollectionsByCollectionId();
    });
  }, [
    changedCharts,
    updateChartMutationAsync,
    getChartCollectionsByCollectionId,
    chartCollectionsByCollectionId,
  ]);

  // on Create Save Collection
  const onCreateSaveCollection = (labelName: string) => {
    if (shownModalCreateSaveCollection === "save") {
      const promises = [];
      if (labelName !== chartCollectionsData.name) {
        promises.push(
          updateCollectionMutation
            .mutateAsync({
              collectionId: chartCollectionsData.id,
              name: labelName,
            })
            .then((data) => {
              setChartCollectionsData(data);
              return data;
            })
        );
      }

      promises.push(
        _.map(chartsUpdate, (update, id) => {
          if (_.isEmpty(update)) {
            return Promise.resolve(false);
          } else {
            const chart = _.find(chartCollectionsByCollectionId, ["id", id]);
            return chart
              ? updateChartMutation({
                  ...update,
                  collectionId: chart.collection_id,
                  chartId: id,
                })
              : Promise.resolve(false);
          }
        })
      );

      Promise.all(promises).then(() => {
        setShownModalCreateSaveCollection("");
      });
    } else {
      setShownModalCreateSaveCollection("");
    }
  };

  // on Click Copy Chart
  const onClickCopyChart = () => {
    // console.log('on Click Copy Chart')
  };

  // on Click Edit Chart
  const onClickEditChart = (chartData: ChartData) => {
    // console.log('on Click Edit Chart')
    setEditingChartData(chartData);
    setShownModalCreateEditChart("edit");
  };

  const deleteChartMutation = useMutation((payload: any) => {
    return DataSvc.deleteChart(payload.collectionId, payload.chartId);
  });

  // on Delete Chart
  const onDeleteChart = () => {
    if (editingChartData) {
      deleteChartMutation
        .mutateAsync({
          collectionId: editingChartData.collection_id,
          chartId: editingChartData.id,
        })
        .then(() => {
          _.pull(chartCollectionsByCollectionIdData, editingChartData);
          _.unset(chartsUpdate, editingChartData.id);
          setChartCollectionsByCollectionIdData(
            chartCollectionsByCollectionIdData
          );
          setShownModalCreateEditChart("");
        });
    }
  };

  const createChartMutation = useMutation((payload: any) => {
    return DataSvc.createChartByTemplate(
      payload.collectionId,
      _.omit(payload, "collectionId")
    );
  });

  // on Create Save Chart
  const onCreateSaveChart = (
    labelName: string,
    selectedTemplateChart: ChartData
  ) => {
    if (shownModalCreateEditChart === "edit") {
      updateChartMutationAsync({
        name: labelName,
        chartId: editingChartData.id,
        collectionId: chartCollectionsData.id,
      }).then((data) => {
        const index = _.findIndex(chartCollectionsByCollectionIdData, [
          "id",
          editingChartData.id,
        ]);
        chartCollectionsByCollectionIdData.splice(index, 1, data);
        setChartCollectionsByCollectionIdData(
          _.cloneDeep(chartCollectionsByCollectionIdData)
        );
        setShownModalCreateEditChart("");
      });
    } else {
      createChartMutation
        .mutateAsync({
          name: labelName,
          template_id: selectedTemplateChart.id,
          collectionId: chartCollectionsData.id,
        })
        .then((data) => {
          getChartCollectionsByCollectionId();
          chartCollectionsByCollectionIdData.push(data);
          setChartCollectionsByCollectionIdData(
            _.cloneDeep(chartCollectionsByCollectionIdData)
          );
          setShownModalCreateEditChart("");

          chartsUpdate[data.id] = {};
          setChartsUpdate({ ...chartsUpdate });
        });
    }
  };

  // on Click Move Up
  const onClickMoveUp = (index: number) => {
    const chartCollectionsByCollectionIdDataTemp =
      chartCollectionsByCollectionIdData;

    const chartDataTemp = chartCollectionsByCollectionIdDataTemp[index];
    chartCollectionsByCollectionIdDataTemp[index] =
      chartCollectionsByCollectionIdDataTemp[index - 1];
    chartCollectionsByCollectionIdDataTemp[index - 1] = chartDataTemp;

    setChartCollectionsByCollectionIdData(
      _.cloneDeep(chartCollectionsByCollectionIdDataTemp)
    );

    if (
      chartCollectionsByCollectionIdDataTemp[index].display_order !==
      index + 1
    ) {
      const id = chartCollectionsByCollectionIdDataTemp[index].id;
      chartsUpdate[id] = {
        ...chartsUpdate[id],
        display_order: index + 1,
      };
      setChartsUpdate({ ...chartsUpdate });
    }
    if (
      chartCollectionsByCollectionIdDataTemp[index - 1].display_order !== index
    ) {
      const id = chartCollectionsByCollectionIdDataTemp[index - 1].id;
      chartsUpdate[id] = {
        ...chartsUpdate[id],
        display_order: index,
      };
      setChartsUpdate({ ...chartsUpdate });
    }
  };

  // on Click Move Down
  const onClickMoveDown = (index: number) => {
    const chartCollectionsByCollectionIdDataTemp =
      chartCollectionsByCollectionIdData;

    const chartDataTemp = chartCollectionsByCollectionIdDataTemp[index];
    chartCollectionsByCollectionIdDataTemp[index] =
      chartCollectionsByCollectionIdDataTemp[index + 1];
    chartCollectionsByCollectionIdDataTemp[index + 1] = chartDataTemp;

    setChartCollectionsByCollectionIdData(
      _.cloneDeep(chartCollectionsByCollectionIdDataTemp)
    );

    if (
      chartCollectionsByCollectionIdDataTemp[index].display_order !==
      index + 1
    ) {
      const id = chartCollectionsByCollectionIdDataTemp[index].id;
      chartsUpdate[id] = {
        ...chartsUpdate[id],
        display_order: index + 1,
      };
      setChartsUpdate({ ...chartsUpdate });
    }
    if (
      chartCollectionsByCollectionIdDataTemp[index + 1].display_order !==
      index + 2
    ) {
      const id = chartCollectionsByCollectionIdDataTemp[index + 1].id;
      chartsUpdate[id] = {
        ...chartsUpdate[id],
        display_order: index + 2,
      };
      setChartsUpdate({ ...chartsUpdate });
    }
  };

  // is Having Charts
  const isHavingCharts = () => {
    return chartCollectionsByCollectionIdData.length > 0;
  };

  return (
    <>
      <div className="right-content analysis-collections">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("analysis")}</div>
        </div>

        {chartCollectionsData && (
          <>
            <CollectionsFilterBar
              title={chartCollectionsData.name}
              onClickSaveCollection={onClickSaveCollection}
              onClickCreateCollection={() => {
                setShownModalCreateSaveCollection("create");
              }}
            />

            {isHavingCharts() && (
              <>
                {chartCollectionsByCollectionIdData.map(
                  (item: ChartData, index) => (
                    <React.Fragment key={index}>
                      <CollectionChartArea
                        chartData={item}
                        totalLength={chartCollectionsByCollectionIdData.length}
                        chartIndex={index}
                        onClickCopyChart={() => {
                          onClickCopyChart();
                          ReactGA.event({
                            category: "analysis-chart",
                            action: "Copy chart",
                          });
                        }}
                        onClickEditChart={() => {
                          onClickEditChart(item);
                        }}
                        onClickMoveUp={() => {
                          onClickMoveUp(index);
                          ReactGA.event({
                            category: "analysis-chart",
                            action: "Move chart up",
                          });
                        }}
                        onClickMoveDown={() => {
                          onClickMoveDown(index);
                          ReactGA.event({
                            category: "analysis-chart",
                            action: "Move chart down",
                          });
                        }}
                        onUpdate={(id: number, payload: any) => {
                          chartsUpdate[id] = {
                            ...chartsUpdate[id],
                            ...payload,
                          };
                          setChartsUpdate({ ...chartsUpdate });
                        }}
                      />
                    </React.Fragment>
                  )
                )}
              </>
            )}

            <div className={`add-chart ${!isHavingCharts() ? "mt-100" : ""}`}>
              <a
                className="btn btn-border"
                onClick={() => {
                  setShownModalCreateEditChart("create");
                }}
              >
                <i className="icons icon-add"></i>
                {t("add_new_chart")}
              </a>
            </div>
          </>
        )}
      </div>
      {shownModalCreateSaveCollection !== "" && (
        <ModalCreateSaveCollection
          title={chartCollectionsData.name}
          isEdit={shownModalCreateSaveCollection === "save"}
          onClose={() => {
            setShownModalCreateSaveCollection("");
          }}
          onCreateSaveCollection={(labelName: string) => {
            onCreateSaveCollection(labelName);
          }}
        />
      )}

      {shownModalCreateEditChart !== "" && (
        <ModalCreateEditChart
          isEdit={shownModalCreateEditChart === "edit"}
          editingChartData={editingChartData}
          onClose={() => {
            setShownModalCreateEditChart("");
          }}
          onDelete={() => {
            onDeleteChart();
          }}
          onCreateSaveChart={(
            labelName: string,
            selectedTemplateChart: ChartData
          ) => {
            onCreateSaveChart(labelName, selectedTemplateChart);
          }}
        />
      )}
    </>
  );
};

export default withRouter(AnalysisCollectionsPage);
