import {
  LocFeatureUseLimitData,
  OrgFeatureUseLimitData
} from "./FeatureUseLimitsData";
import { FeatureTypes, AllFeatures } from "./FeatureUseLimitsData.const";
import { AccountData } from "../AccountData";
import { LocationData } from "../LocationData";
import { OrganizationData } from "../OrganizationData";

export interface CommonFeatureUseLimitData {
  can_use: boolean;
  can_use_times: number;
  feature_id: string;
  id: string;
  specific_rule_expire_at: string;
  target_id: string;
  times_triggered_in_cycle: number;
  type: FeatureTypes;
  name: string;
  is_metered: boolean;
}

export interface CommonFeatureData {
  created_at: string;
  created_by: string;
  default_can_use: boolean;
  default_use_limit: number;
  id: string;
  is_metered: boolean;
  last_modified_at?: string;
  last_modified_by: string;
  name: AllFeatures;
  use_limit_cycle: string;
}

// Takes the differences in the data structure of the feature use limits data and converts it to a common format
export class FeatureDataConverter {
  static convertOrganizationData(data: OrganizationData) {
    return {
      ...data,
      commonFeatureUseLimit: data.org_feature_use_limit?.map(
        this.convertOrgFeatureData
      )
    };
  }

  private static convertOrgFeatureData(
    data: OrgFeatureUseLimitData
  ): CommonFeatureUseLimitData {
    const { org_feature, target_org_id, ...rest } = data;
    return {
      ...rest,
      target_id: target_org_id,
      type: FeatureTypes.ORG,
      name: org_feature.name,
      is_metered: org_feature.is_metered
    };
  }

  static convertLocationData(data: LocationData): LocationData {
    return {
      ...data,
      commonFeatureUseLimit: data.loc_feature_use_limit?.map(
        this.convertLocFeatureData
      )
    };
  }

  private static convertLocFeatureData(
    data: LocFeatureUseLimitData
  ): CommonFeatureUseLimitData {
    const { loc_feature, target_loc_id, ...rest } = data;
    return {
      ...rest,
      target_id: target_loc_id,
      type: FeatureTypes.LOC,
      name: loc_feature.name,
      is_metered: loc_feature.is_metered
    };
  }

  // Only converts the organization data, not the user data
  // Will require work to handle user_feature_use_limits at a later date
  static convertUserData(data: AccountData) {
    const { organization, ...rest } = data;
    if (!organization) {
      return data;
    }
    return {
      ...rest,
      organization: FeatureDataConverter.convertOrganizationData(organization)
    };
  }
}
